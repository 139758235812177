import { Injectable } from '@angular/core';
import { environment } from '../../../environment/environment';

@Injectable({
    providedIn: 'root',
})
export class GoogleTagManagerService {
    constructor() {}

    setGoogleTagManagerScript(gtmId: string) {
        if (environment.production) {
            const head = document.getElementsByTagName('head')[0];
            const script = document.createElement('script');
            script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmId}');`;

            head.insertBefore(script, head.firstChild);

            const body = document.getElementsByTagName('body')[0];
            const noscript = document.createElement('noscript');
            const iframe = document.createElement('iframe');
            iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
            iframe.height = '0';
            iframe.width = '0';
            iframe.style.display = 'none';
            iframe.style.visibility = 'hidden';

            body.insertBefore(noscript, body.firstChild);
            noscript.insertBefore(iframe, noscript.firstChild);
        }
    }
}
