<ng-container *ngIf="campaigns$ | async as campaigns">
    <div class="w-full" style="margin-bottom: -22px" *ngIf="campaigns.length">
        <p-carousel
            [showNavigators]="true"
            [value]="campaigns"
            [numVisible]="1"
            [numScroll]="1"
            [circular]="true"
            [autoplayInterval]="5000"
            indicatorsContentClass="indicatorsContentClass"
        >
            <ng-template let-campaign pTemplate="item">
                <div
                    [ngStyle]="{ 'background-image': 'url(' + campaign.imageUrl + ')' }"
                    class="hero-image flex justify-content-evenly align-content-center align-items-center flex-column md:flex-row"
                >
                    <div class="flex flex-column">
                        <div class="text-5xl font-bold" [ngStyle]="{'color': campaign.campaignTextColor || campaignTextColor}">
                            {{ campaign.title }}
                        </div>
                        <div class="text-2xl font-bold" [ngStyle]="{'color': campaign.campaignTextColor || campaignTextColor}">{{ campaign.description }}</div>
                    </div>
                    <div class="">
                        <p-button
                            (click)="goToCampaign(campaign.link)"
                            label="{{ 'Button Browse Campaign' | translate }}"
                            icon="pi fa-solid fa-tag"
                            severity="secondary"
                            size="large"
                        ></p-button>
                    </div>
                </div>
            </ng-template>
        </p-carousel>
    </div>
</ng-container>
