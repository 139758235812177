import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { CommonModule, NgClass } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

import { LayoutService } from './service/app.layout.service';
import { BrandService } from '../../application/services/brand.service';
import { DropdownModule } from 'primeng/dropdown';
import { UmbracoTranslateLoader } from '../../application/umbraco-translate-loader';
import { Language } from '../../../../common/models/language';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    standalone: true,
    imports: [RouterLink, NgClass, TranslateModule, DropdownModule, FormsModule, CommonModule],
})
export class AppTopBarComponent implements OnInit, OnDestroy {
    items!: MenuItem[];
    languageOptions: Language[] = [];
    translatedLanguageOptions: Language[] = [];
    selectedLanguage: string | null = this.currentLanguage;
    private languageChangeSubscription!: Subscription;

    @ViewChild('menuButton') menuButton!: ElementRef;
    @ViewChild('topBarMenuButton') topbarMenuButton!: ElementRef;
    @ViewChild('topBarMenu') menu!: ElementRef;

    constructor(
        public layoutService: LayoutService,
        private brandService: BrandService,
        private translateService: TranslateService,
        private umbracoTranslateLoader: UmbracoTranslateLoader,
        public router: Router,
    ) {}

    ngOnInit(): void {
        // Subscribe to language updates
        this.umbracoTranslateLoader.languages$.subscribe((languages: Language[]) => {
            this.languageOptions = languages;
            this.translateLanguageOptions();
        });

        this.umbracoTranslateLoader.getConfiguredLanguages();

        const storedLanguage = localStorage.getItem('selectedLanguage');
        if (storedLanguage) {
            this.changeLanguage(storedLanguage);
        } else {
            this.selectedLanguage = this.currentLanguage;
        }

        this.languageChangeSubscription = this.translateService.onLangChange.subscribe(() => {
            this.translateLanguageOptions();
            this.fetchDataWithNewLanguage();
        });
    }

    translateLanguageOptions(): void {
        this.translatedLanguageOptions = this.languageOptions.map((option) => ({
            isoCode: option.isoCode,
            name: this.translateService.instant(option.name),
        }));
    }

    changeLanguage(languageCode: string): void {
        this.translateService.use(languageCode);
        this.selectedLanguage = languageCode;
        localStorage.setItem('selectedLanguage', languageCode);
    }

    fetchDataWithNewLanguage(): void {
        this.brandService.setCurrentBrandId();
    }

    get currentLanguage(): string {
        return this.translateService.currentLang;
    }

    get brandLogoDark(): string {
        return this.brandService.getBrandLogo('dark');
    }

    get brandLogoAlt(): string {
        return this.translateService.instant('brandLogoAlt', this.brandService.brandNameString);
    }

    ngOnDestroy(): void {
        if (this.languageChangeSubscription) {
            this.languageChangeSubscription.unsubscribe();
        }
    }
}
