import { OnDestroy } from '@angular/core';
import { Component } from '@angular/core';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';

import { AppMenuitemComponent } from './app.menuitem.component';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    standalone: true,
    imports: [NgIf, NgFor, AppMenuitemComponent, AsyncPipe],
})
export class AppMenuComponent implements OnDestroy {
    menuItems: MenuItem[] = [];

    private onDestroy = new Subject<void>();

    constructor() {}

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();
    }
}
