import { Component } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';

import { BrandService } from '../../application/services/brand.service';

@Component({
    selector: 'app-footer',
    templateUrl: './app.footer.component.html',
    standalone: true,
    imports: [ButtonModule, TranslateModule],
})
export class AppFooterComponent {
    constructor(
        public brandService: BrandService,
        private translateService: TranslateService,
    ) {}

    get brandLogoWhite(): string {
        return this.brandService.getBrandLogo('white');
    }

    get brandLogoAlt(): string {
        return this.translateService.instant('brandLogoAlt', this.brandService.brandNameString);
    }

    get brandNameString(): string {
        return this.brandService.brandNameString;
    }
}
