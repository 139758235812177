<div class="layout-top-bar justify-content-between xl:justify-content-center">
    <a class="layout-top-bar-logo col-2" routerLink="">
        <img [src]="brandLogoDark" [alt]="brandLogoAlt" class="w-8rem xl:w-10rem" alt="logo" width="100%" />
        <div class="text-2xl text-primary ml-3 font-bold">{{ 'appName' | translate }}</div>
    </a>

    <!--    <button-->
    <!--        #topBarMenuButton-->
    <!--        class="p-link layout-top-bar-menu-button layout-top-bar-button col-1"-->
    <!--        (click)="layoutService.showProfileSidebar()"-->
    <!--    >-->
    <!--        <i class="pi fa-solid fa-bars"></i>-->
    <!--    </button>-->

    <div
        #topBarMenu
        class="layout-top-bar-menu col-12 md:col-9 ml-1 justify-content-evenly xl:justify-content-around align-content-center"
        [ngClass]="{ 'layout-top-bar-menu-mobile-active': layoutService.state.profileSidebarVisible }"
    >
        <div
            class="flex flex-column lg:flex-row col-12 lg:col-9 justify-content-evenly font-semibold gap-1 lg:gap-2"
        ></div>

        <!--        <div-->
        <!--            class="flex flex-column md:flex-row col-12 lg:col-3 align-items-start md:align-items-center align-content-center justify-content-evenly xl:justify-content-end gap-1 lg:gap-2"-->
        <!--        >-->
        <!--            <p-dropdown-->
        <!--                id="language-dropdown"-->
        <!--                class="mr-2"-->
        <!--                [options]="languageOptions"-->
        <!--                [showClear]="false"-->
        <!--                (onChange)="changeLanguage($event.value)"-->
        <!--                optionValue="code"-->
        <!--                optionLabel="name"-->
        <!--            ></p-dropdown>-->
        <!--        </div>-->
    </div>
</div>
