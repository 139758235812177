export const environment = {
    production: true,
    apiKey: 'Qs3P9uZf29JNEwpnWjgGNFnNvZXT8FuXyDhXEa8bh',
    copApiKey: '2bif7DYHhzVBBM3yaBcxjfXCXZMcd3CcWHkCKPtVk',
    apiUrl: 'https://backend.kaistav2.bassadoneservices.com',
    brands: [
        {
            name: 'Hyundai',
            id: '0563f101-adad-4e0b-9e5a-3a8c0f954057',
        },
        {
            name: 'Nissan',
            id: 'd262bfbc-35bd-4d39-b804-29b401721239',
        }
    ]
};
