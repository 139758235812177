import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { debounceTime, map, Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { AccordionModule } from 'primeng/accordion';
import { SelectButtonModule } from 'primeng/selectbutton';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ButtonModule } from 'primeng/button';
import { CopService } from '../../services/cop.service';
import { Campaign } from 'src/common/models/campaign';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-filter',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        AccordionModule,
        DropdownModule,
        SelectButtonModule,
        CheckboxModule,
        ReactiveFormsModule,
        ProgressSpinnerModule,
        ButtonModule,
    ],
    templateUrl: './filter.component.html',
    styleUrl: './filter.component.scss',
})
export class FilterComponent implements OnInit, OnChanges, OnDestroy {
    @Input() quickFilter: string | undefined;
    @Output() filterChanged: EventEmitter<void> = new EventEmitter<void>();

    sortOrderOptions: any[] = [];
    private langChangeSub!: Subscription;

    modelOptions: { label: string; value: string }[] = [];
    bodyTypeOptions: { label: string; value: string }[] = [];
    driveTypeOptions: { label: string; value: string }[] = [];
    drivingForceTypeOptions: { label: string; value: string }[] = [];
    transmissionTypeOptions: { label: string; value: string }[] = [];

    campaigns$: Observable<Campaign[]> = this.copService.campaigns$;
    campaignCodeOptions$ = this.campaigns$.pipe(
        map((campaigns: Campaign[]) => campaigns.map((campaign) => ({ label: campaign.title, value: campaign.code }))),
    );

    filters: FormGroup = this.formBuilder.group(this.buildDefaultControls());
    originalCampaignCode: string | null = null;
    dynamicFiltersLoading$ = this.copService.dynamicFiltersLoading$;

    campaignCodeOptions: { label: string; value: string }[] = [];

    private onDestroy = new Subject<void>();

    constructor(
        private formBuilder: FormBuilder,
        private copService: CopService,
        private translateService: TranslateService,
        private route: ActivatedRoute,
        private router: Router,
    ) {}

    ngOnInit() {
        this.initializeFilters();
        this.subscribeToQueryParams();
        this.subscribeToFilterChanges();
        this.subscribeToDynamicFilters();

        this.buildAndSetSortOrderOptions();
        this.langChangeSub = this.translateService.onLangChange.subscribe(() => {
            this.buildAndSetSortOrderOptions();
        });

        this.campaignCodeOptions$.subscribe((campaignOptions) => {
            this.campaignCodeOptions = campaignOptions;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['quickFilter']?.currentValue) {
            this.handleQuickFilterChange(changes['quickFilter'].currentValue, false);

            setTimeout(() => {
                this.updateUrlWithFilters();
                this.triggerApiCall();
            }, 0);
        }
    }

    ngOnDestroy() {
        if (this.langChangeSub) {
            this.langChangeSub.unsubscribe();
        }

        this.onDestroy.next();
        this.onDestroy.complete();
    }

    clearFilters(): void {
        this.copService.filterString = '';
        this.clearCampaignCode();
        this.originalCampaignCode = null;
        this.clearFilterOptions();
        this.resetFilters(false);
        this.refreshFilters();
        this.preserveScroll(() => this.filterChanged.emit());
    }

    clearCampaignCode() {
        this.router.navigate([], {
            queryParams: { campaignCode: null },
            queryParamsHandling: 'merge',
            replaceUrl: true,
        });
    }

    private buildAndSetSortOrderOptions(): void {
        this.sortOrderOptions = [
            { label: this.translateService.instant('Ascending'), value: 'ASC' },
            { label: this.translateService.instant('Descending'), value: 'DESC' },
        ];
    }

    private buildDefaultControls() {
        return {
            SortOrder: 'ASC',
            ModelBodyType: null,
            ModelDriveType: null,
            ModelDrivingForce: null,
            DrivingForceOption: null,
            ModelTransmission: null,
            ModelSeries: null,
            CampaignCode: null,
        };
    }

    private initializeFilters(): void {
        this.copService.fetchDynamicFilterOptions();
    }

    private subscribeToQueryParams(): void {
        this.route.queryParams.subscribe((params) => {
            const campaignCodes: string = params['campaignCode'] || '';
            const currentValue = this.filters.get('CampaignCode')?.value;
            if (campaignCodes && !this.isSameCampaignCode(currentValue, campaignCodes)) {
                this.filters.get('CampaignCode')?.setValue(campaignCodes.split(','), { emitEvent: false });
                if (this.originalCampaignCode !== campaignCodes) {
                    this.triggerApiCall();
                    this.originalCampaignCode = campaignCodes;
                }
            } else if (!campaignCodes && currentValue) {
                this.filters.get('CampaignCode')?.setValue(null, { emitEvent: false });
                if (this.originalCampaignCode) {
                    this.triggerApiCall();
                    this.originalCampaignCode = null;
                }
            }
        });
    }

    private subscribeToFilterChanges(): void {
        this.filters.valueChanges.pipe(debounceTime(200), takeUntil(this.onDestroy)).subscribe(() => {
            this.updateUrlWithFilters();
            this.triggerApiCall();
            this.preserveScroll(() => this.filterChanged.emit());
        });
    }

    private subscribeToDynamicFilters(): void {
        this.copService.models$.pipe(takeUntil(this.onDestroy)).subscribe((models) => {
            this.modelOptions = models || [];
        });
        this.copService.bodyTypeOptions$.pipe(takeUntil(this.onDestroy)).subscribe((options) => {
            this.bodyTypeOptions = options || [];
        });
        this.copService.driveTypeOptions$.pipe(takeUntil(this.onDestroy)).subscribe((options) => {
            this.driveTypeOptions = options || [];
        });
        this.copService.drivingForceOptions$.pipe(takeUntil(this.onDestroy)).subscribe((options) => {
            this.drivingForceTypeOptions = options || [];
        });
        this.copService.transmissionOptions$.pipe(takeUntil(this.onDestroy)).subscribe((options) => {
            this.transmissionTypeOptions = options || [];
        });
    }

    private resetFilters(emitEvent: boolean = true): void {
        const filterString = this.copService.filterString;
        if (filterString) {
            this.applyExistingFilters(filterString, emitEvent);
        } else {
            this.filters.patchValue(this.buildDefaultControls(), { emitEvent });
        }
        if (emitEvent) {
            this.copService.fetchDynamicFilterOptions();
        }
    }

    private applyExistingFilters(filterString: string, emitEvent: boolean = true): void {
        const filters = filterString.split('&');
        filters.forEach((filter) => {
            const [key, value] = filter.split('=');
            this.filters.get(key)?.setValue(value.split(','), { emitEvent });
        });
    }

    private handleQuickFilterChange(filterType: string, emitEvent: boolean = true): void {
        this.copService.filterString = '';

        if (filterType === 'campaignCode') {
            this.applyCampaignCodeFilter(emitEvent);
            return;
        }

        this.clearCampaignCode();
        this.resetFilters(emitEvent);

        const filterMap: { [key: string]: { control: string; value: any } } = {
            ev: { control: 'ModelDrivingForce', value: ['EL'] },
            awd: { control: 'ModelDriveType', value: ['AWD'] },
            phev: { control: 'DrivingForceOption', value: 'PHEV' },
            automatic: { control: 'ModelTransmission', value: ['A', '1'] },
        };

        const filter = filterMap[filterType];
        if (filter) {
            this.filters.get(filter.control)?.setValue(filter.value, { emitEvent });
        }
    }

    private applyCampaignCodeFilter(emitEvent: boolean = true): void {
        const allCampaignCodes = this.campaignCodeOptions.map((option) => option.value);
        this.filters.get('CampaignCode')?.setValue(allCampaignCodes, { emitEvent: false });
        if (!emitEvent) return;

        const campaignCodesStr = allCampaignCodes.join(',');
        this.originalCampaignCode = campaignCodesStr;

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: allCampaignCodes.length ? { campaignCode: campaignCodesStr } : {},
            queryParamsHandling: 'merge',
            replaceUrl: true,
        });

        this.copService.filterString = this.filterString;
        this.refreshFilters();
        this.filterChanged.emit();
    }

    private updateUrlWithFilters(): void {
        const campaignCodes = this.filters.get('CampaignCode')?.value;
        const params = campaignCodes?.length ? { campaignCode: campaignCodes.join(',') } : {};
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
            queryParamsHandling: 'merge',
            replaceUrl: true,
        });
    }

    private get filterString(): string {
        let filterString = '';

        Object.keys(this.filters.value).forEach((key, index) => {
            if (this.filters.value[key] && this.filters.value[key].length) {
                if (index === 0) {
                    filterString += `${key}=${this.filters.value[key]}`;
                } else {
                    filterString += `&${key}=${this.filters.value[key]}`;
                }
            }
        });

        return filterString;
    }

    private triggerApiCall(): void {
        const scrollPosition = window.scrollY;

        this.copService.filterString = this.filterString;
        this.copService.getAvailableModels();
        this.modelOptions = [];
        this.copService.fetchDynamicFilterOptions();

        setTimeout(() => {
            window.scrollTo(0, scrollPosition);
        }, 0);
    }

    private isSameCampaignCode(currentValue: any, campaignCodes: string): boolean {
        if (!currentValue && !campaignCodes) return true;
        if (Array.isArray(currentValue) && campaignCodes) {
            return currentValue.join(',') === campaignCodes;
        }
        return currentValue === campaignCodes;
    }

    private clearFilterOptions(): void {
        this.modelOptions = [];
        this.bodyTypeOptions = [];
        this.driveTypeOptions = [];
        this.drivingForceTypeOptions = [];
        this.transmissionTypeOptions = [];
    }

    private refreshFilters(): void {
        this.copService.getAvailableModels();
        this.copService.fetchDynamicFilterOptions();
    }

    /**
     * Preserves the current scroll position while executing subsequent logic.
     * @param callback Callback to execute after restoring scroll position.
     */
    private preserveScroll(callback?: () => void): void {
        const scrollPosition = window.scrollY;
        setTimeout(() => {
            window.scrollTo(0, scrollPosition);
            if (callback) {
                callback();
            }
        }, 0);
    }
}
