<p-accordion class="w-full" [activeIndex]="0">
    <p-accordionTab iconPos="end">
        <ng-template pTemplate="header">
            <span class="flex align-items-center gap-2 w-full">
                <i class="fa-solid fa-filter"></i>
                <span class="font-bold white-space-nowrap">{{ 'Filters' | translate }}</span>
            </span>
        </ng-template>
        <form [formGroup]="filters">
            <div class="grid grid-nogutter flex-column gap-1">
                <div class="col mb-1">
                    <p-button
                        [style]="{ width: '100%' }"
                        (click)="clearFilters()"
                        label="{{ 'Button Clear Filters' | translate }}"
                        icon="pi fa-solid fa-eraser"
                    ></p-button>
                </div>
                <div class="col">
                    <p-accordion class="w-full">
                        <p-accordionTab iconPos="end" id="campaignFilterAccordionTab">
                            <ng-template pTemplate="header">
                                <span class="flex align-items-center gap-2 w-full">
                                    <i class="fa-solid fa-bullhorn"></i>
                                    <span class="font-bold white-space-nowrap">{{
                                        'Button Filter Only Campaign' | translate
                                    }}</span>
                                </span>
                            </ng-template>
                            <div class="flex flex-column gap-1 justify-content-center">
                                <div *ngFor="let campaignCodeOption of (campaignCodeOptions$ | async)" class="field-checkbox">
                                    <p-checkbox
                                        label="{{ campaignCodeOption.label | translate }}"
                                        name="campaignCodeOption"
                                        [value]="campaignCodeOption.value"
                                        formControlName="CampaignCode"
                                    ></p-checkbox>
                                </div>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                </div>
                <div class="col">
                    <p-accordion class="w-full">
                        <p-accordionTab iconPos="end" id="orderAccordionTab">
                            <ng-template pTemplate="header">
                                <span class="flex align-items-center gap-2 w-full">
                                    <i class="fa-solid fa-sort"></i>
                                    <span class="font-bold white-space-nowrap">{{ 'Order' | translate }}</span>
                                </span>
                            </ng-template>
                            <div class="flex justify-content-center">
                                <p-selectButton
                                    [options]="sortOrderOptions"
                                    formControlName="SortOrder"
                                    optionLabel="label"
                                    optionValue="value"
                                    unselectable
                                ></p-selectButton>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                </div>
                <div class="col">
                    <p-accordion class="w-full">
                        <p-accordionTab iconPos="end" id="sizeAccordionTab">
                            <ng-template pTemplate="header">
                                <span class="flex align-items-center gap-2 w-full">
                                    <i class="fa-solid fa-car"></i>
                                    <span class="font-bold white-space-nowrap">{{ 'Body Type' | translate }}</span>
                                </span>
                            </ng-template>
                            <div class="flex flex-column gap-1 justify-content-center">
                                <div *ngFor="let bodyType of bodyTypeOptions" class="field-checkbox">
                                    <p-checkbox
                                        label="{{ bodyType.label | translate }}"
                                        name="size"
                                        [value]="bodyType.value"
                                        formControlName="ModelBodyType"
                                    ></p-checkbox>
                                </div>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                </div>
                <div class="col">
                    <p-accordion class="w-full">
                        <p-accordionTab iconPos="end" id="driveTypeAccordionTab">
                            <ng-template pTemplate="header">
                                <span class="flex align-items-center gap-2 w-full">
                                    <i class="fa-solid fa-truck-monster"></i>
                                    <span class="font-bold white-space-nowrap">{{ 'Drive Type' | translate }}</span>
                                </span>
                            </ng-template>
                            <div class="flex flex-column gap-1 justify-content-center">
                                <div *ngFor="let driveType of driveTypeOptions" class="field-checkbox">
                                    <p-checkbox
                                        label="{{ driveType.label | translate }}"
                                        name="driveType"
                                        [value]="driveType.value"
                                        formControlName="ModelDriveType"
                                    ></p-checkbox>
                                </div>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                </div>
                <div class="col">
                    <p-accordion class="w-full">
                        <p-accordionTab iconPos="end" id="drivingForceTypeAccordionTab">
                            <ng-template pTemplate="header">
                                <span class="flex align-items-center gap-2 w-full">
                                    <i class="fa-solid fa-gas-pump"></i>
                                    <span class="font-bold white-space-nowrap">{{ 'Driving Force' | translate }}</span>
                                </span>
                            </ng-template>
                            <div class="flex flex-column gap-1 justify-content-center">
                                <div *ngFor="let drivingForce of drivingForceTypeOptions" class="field-checkbox">
                                    <p-checkbox
                                        label="{{ drivingForce.label | translate }}"
                                        name="fuelType"
                                        [value]="drivingForce.value"
                                        formControlName="ModelDrivingForce"
                                    ></p-checkbox>
                                </div>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                </div>
                <div class="col">
                    <p-accordion class="w-full">
                        <p-accordionTab iconPos="end" id="transmissionAccordionTab">
                            <ng-template pTemplate="header">
                                <span class="flex align-items-center gap-2 w-full">
                                    <i class="fa-solid fa-tape"></i>
                                    <span class="font-bold white-space-nowrap">{{
                                        'Transmission Type' | translate
                                    }}</span>
                                </span>
                            </ng-template>
                            <div class="flex flex-column gap-1 justify-content-center">
                                <div *ngFor="let transmissionType of transmissionTypeOptions" class="field-checkbox">
                                    <p-checkbox
                                        label="{{ transmissionType.label | translate }}"
                                        name="transmissionType"
                                        [value]="transmissionType.value"
                                        formControlName="ModelTransmission"
                                    ></p-checkbox>
                                </div>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                </div>
                <div class="col">
                    <p-accordion class="w-full">
                        <p-accordionTab iconPos="end" id="modelAccordionTab">
                            <ng-template pTemplate="header">
                                <span class="flex align-items-center gap-2 w-full">
                                    <i class="fa-solid fa-car-side"></i>
                                    <span class="font-bold white-space-nowrap">{{ 'Model' | translate }}</span>
                                </span>
                            </ng-template>
                            <div class="flex flex-column gap-1 justify-content-center">
                                <div *ngFor="let model of modelOptions" class="field-checkbox">
                                    <p-checkbox
                                        [label]="model.label"
                                        name="group"
                                        [value]="model.value"
                                        formControlName="ModelSeries"
                                    ></p-checkbox>
                                </div>
                                <div
                                    class="flex justify-content-center"
                                    *ngIf="(loadingModels$ | async) && !modelOptions.length"
                                >
                                    <p-progressSpinner strokeWidth="2"></p-progressSpinner>
                                </div>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                </div>
            </div>
        </form>
    </p-accordionTab>
</p-accordion>
