<ng-container>
    <div class="grid gap-2" *ngIf="vehicle; else skeletons">
        <p-toast position="top-center" [life]="5000"/>
        <div class="col-12 sm:col-8 sm:col-offset-2 lg:col-6 lg:col-offset-3">
            <h1 class="text-3xl">
                {{ 'Request Offer Title' | translate }}
                - {{ vehicle.MakeName }} {{ vehicle.ModelSeries }} {{ vehicle.ModelName }}
            </h1>
        </div>
        <div
            class="col-12 sm:col-8 sm:col-offset-2 lg:col-6 lg:col-offset-3 flex gap-2 justify-content-between flex-wrap md:flex-nowrap"
        >
            <form [formGroup]="contactForm" class="p-fluid p-formgrid grid">
                <div class="col-12">
                    <app-text-field
                        name="firstName"
                        formControlName="firstName"
                        [maxlength]="10"
                        [required]="true"
                    ></app-text-field>
                </div>
                <div class="col-12">
                    <app-text-field
                        name="lastName"
                        formControlName="lastName"
                        [maxlength]="100"
                        [required]="true"
                    ></app-text-field>
                </div>
                <div class="col-12">
                    <app-email-field
                        name="email"
                        formControlName="email"
                        [maxlength]="100"
                        [required]="true"
                    ></app-email-field>
                </div>
                <div class="col-12">
                    <app-phone-field name="phone" formControlName="phone" [required]="true"></app-phone-field>
                </div>
                <div class="col-12">
                    <app-text-field name="address" formControlName="address" [maxlength]="100"></app-text-field>
                </div>
                <div class="col-12 grid">
                    <div class="col-8">
                        <app-text-field name="city" formControlName="city" [maxlength]="100"></app-text-field>
                    </div>
                    <div class="col-4">
                        <app-text-field name="postalCode" formControlName="postalCode" [maxlength]="8"></app-text-field>
                    </div>
                </div>
                <div class="col-12" *ngIf="dealers$ | async as dealers">
                    <app-select-field
                        name="dealer"
                        formControlName="dealer"
                        [options]="dealers"
                        [required]="true"
                    ></app-select-field>
                </div>
                <div class="col-12">
                    <app-text-area-field
                        name="additionalInformation"
                        formControlName="additionalInformation"
                        [maxlength]="500"
                        [required]="false"
                    ></app-text-area-field>
                </div>
                <div class="col-12">
                    <app-radio-button-field
                        name="preferredContactMethod"
                        formControlName="preferredContactMethod"
                        [options]="contactMethodOptions"
                        [required]="false"
                    ></app-radio-button-field>
                </div>
                <div class="col-12">
                    <div class="field flex">
                        <p-checkbox
                            [attr.aria-label]="'privacyPolicyAgreement'"
                            formControlName="privacyPolicyAgreement"
                            value="true"
                            inputId="privacyPolicyAgreement"
                            [style]="{ width: '100%' }"
                        />
                        <label class="mb-0 ml-2" [htmlFor]="'privacyPolicyAgreement'"
                            ><strong>{{ 'yes' | translate | titlecase }}</strong>
                            {{ 'Privacy Policy Agreement Text' | translate }} (<a
                                href="{{ 'Privacy Policy Link' | translate }}"
                                target="_blank"
                                >{{ 'Privacy Policy Link Text' | translate }}</a
                            >).</label
                        >
                    </div>
                    <app-validation-errors [control]="privacyPolicyAgreement"></app-validation-errors>
                </div>
                <div class="col-12 flex gap-2 justify-content-end">
                    <p-button
                        [routerLink]="['../']"
                        [loading]="sendingForm"
                        label="{{ 'Button Go Back' | translate }}"
                        icon="pi fa-solid fa-arrow-left"
                        severity="secondary"
                    ></p-button>
                    <p-button
                        [disabled]="contactForm.invalid || disabledSubmitButton"
                        [loading]="sendingForm"
                        (click)="submit()"
                        label="{{ 'Button Submit Contact Form' | translate }}"
                        icon="pi fa-solid fa-paper-plane"
                    ></p-button>
                </div>
            </form>
        </div>
    </div>
    <ng-template #skeletons>
        <div class="grid gap-2">
            <div class="col-12 sm:col-8 sm:col-offset-2 lg:col-6 lg:col-offset-3">
                <h1>
                    <p-skeleton width="100%" height="3rem"></p-skeleton>
                </h1>
            </div>
            <div
                class="col-12 sm:col-8 sm:col-offset-2 lg:col-6 lg:col-offset-3 flex flex-column gap-2 justify-content-between flex-wrap md:flex-nowrap"
            >
                <div class="col-12">
                    <p-skeleton width="100%" height="60px"></p-skeleton>
                </div>
                <div class="col-12">
                    <p-skeleton width="100%" height="60px"></p-skeleton>
                </div>
                <div class="col-12">
                    <p-skeleton width="100%" height="60px"></p-skeleton>
                </div>
                <div class="col-12">
                    <p-skeleton width="100%" height="60px"></p-skeleton>
                </div>
                <div class="col-12">
                    <p-skeleton width="100%" height="60px"></p-skeleton>
                </div>
                <div class="col-12">
                    <p-skeleton width="100%" height="60px"></p-skeleton>
                </div>
                <div class="col-12">
                    <p-skeleton width="100%" height="60px"></p-skeleton>
                </div>
                <div class="col-12">
                    <p-skeleton width="100%" height="130px"></p-skeleton>
                </div>
                <div class="col-12 flex gap-2 justify-content-end">
                    <p-skeleton width="140px" height="40px"></p-skeleton>
                    <p-skeleton width="236px" height="40px" styleClass="ml-4"></p-skeleton>
                </div>
            </div>
        </div>
    </ng-template>
</ng-container>
