<div class="layout-footer flex flex-column md:flex-row align-items-center justify-content-between p-6 bg-primary">
    <div class="flex flex-column gap-4 justify-content-center p-5">
        <div class="flex">
            <img [src]="brandLogoWhite" [alt]="brandLogoAlt" height="20" class="mt-1" />
            <!--            <div class="text-2xl text-white ml-3 font-bold">{{ brandNameString }}</div>-->
        </div>
        <!--        <div class="flex justify-content-center">-->
        <!--            <p-button-->
        <!--                class="service-button"-->
        <!--                [outlined]="true"-->
        <!--                label="{{ 'Minun ' + brandNameString + ' palvelu' | translate }}"-->
        <!--            ></p-button>-->
        <!--        </div>-->
    </div>
    <div class="flex flex-column gap-4 p-5">
        <div class="flex justify-content-center">
            <div class="text-xs text-white">Sosiaalisessa mediassa</div>
        </div>
        <div class="flex justify-content-center">
            <a href="https://www.facebook.com/HyundaiFinland" target="_blank" class="social-icon">
                <i class="fab fa-facebook"></i>
            </a>
            <a href="https://www.instagram.com/hyundaifinland/" target="_blank" class="social-icon">
                <i class="fab fa-instagram"></i>
            </a>
            <a href="https://www.youtube.com/@HyundaiFinland" target="_blank" class="social-icon">
                <i class="fab fa-youtube"></i>
            </a>
            <a href="https://www.linkedin.com/company/hyundai-motor-finland-oy/" target="_blank" class="social-icon">
                <i class="fab fa-linkedin-in"></i>
            </a>
        </div>
    </div>
    <div class="flex flex-column gap-4 h-full p-5">
        <div class="flex-grow-1"></div>
        <div class="text-base text-white">(C) {{ brandNameString }} 2024</div>
    </div>
</div>
