<div class="field">
    <label [htmlFor]="name" [id]="name" [ngClass]="labelTop ? 'display: block' : ''">{{ getFieldLabel() }}{{ required ? '*' : '' }}</label>
    <textarea
        pInputTextarea
        [attr.aria-label]="name"
        [rows]="rows"
        [cols]="cols"
        [formControl]="formControl"
        [autoResize]="true"
        [style]="width ? 'width: ' + width + 'px;' : 'width: 100%'"
    ></textarea>
    <app-validation-errors [control]="formControl"></app-validation-errors>
</div>
