import { Component } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';

import { BrandService } from '../../application/services/brand.service';
import { CopService } from '../../application/services/cop.service';
import { CopBrandingResponse } from '../../../../common/models/umbraco-responses/cop-branding-response';
import { LinkItem } from '../../../../common/models/umbraco-responses/LinkItem';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-footer',
    templateUrl: './app.footer.component.html',
    standalone: true,
    imports: [ButtonModule, TranslateModule, CommonModule],
})
export class AppFooterComponent {
    cookieLink: LinkItem | null = null;
    privacyPolicyLink: LinkItem | null = null;

    constructor(
        public brandService: BrandService,
        private translateService: TranslateService,
        private copService: CopService,
    ) {
        this.copService.copBrand$.subscribe({
            next: (copBrand: CopBrandingResponse) => {
                if (Object.keys(copBrand).length === 0) {
                    return;
                }
                this.cookieLink = copBrand.properties.cookieLink?.[0] ?? null;
                this.privacyPolicyLink = copBrand.properties.privacyPolicyLink?.[0] ?? null;
            },
        });
    }

    get brandLogoWhite(): string {
        return this.brandService.getBrandLogo('white');
    }

    get brandLogoAlt(): string {
        return this.translateService.instant('brandLogoAlt', this.brandService.brandNameString);
    }

    get brandNameString(): string {
        return this.brandService.brandNameString;
    }
}
