import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { BehaviorSubject, forkJoin, map, Observable, of, switchMap } from 'rxjs';

import { environment } from '../../environment/environment';

import { ContentService } from './services/content.service';
import { Injectable } from '@angular/core';
import { Language } from '../../../common/models/language';

@Injectable({
    providedIn: 'root',
})
export class UmbracoTranslateLoader implements TranslateLoader {
    languages$ = new BehaviorSubject<Language[]>([]);

    constructor(
        private http: HttpClient,
        private contentService: ContentService,
    ) {}

    getTranslation(isoCode: string): Observable<Object> {
        const params = new HttpParams({
            fromObject: {
                isoCode,
            },
        });
        const headers = new HttpHeaders({
            ApiKey: `${environment.apiKey}`,
            'Content-Type': 'application/json',
            accept: 'application/json',
        });
        const url = `${this.contentService.url}umbraco/api/DictionaryApi/GetDictionaryItems`;

        const staticTranslations$ = this.http.get(`../common/i18n/${isoCode}.json`);

        const backendTranslations$ = this.http
            .get<any>(url, {
                headers,
                params,
            })
            .pipe(map((result) => this.transformUmbracoFieldKeys(JSON.parse(result))));

        return forkJoin([staticTranslations$, backendTranslations$]).pipe(
            switchMap((combinedResults) => {
                let translations: Object = {};

                combinedResults.forEach((result) => (translations = Object.assign(translations, result)));
                return of(translations);
            }),
        );
    }

    getConfiguredLanguages(): void {
        const headers = new HttpHeaders({
            ApiKey: `${environment.apiKey}`,
            'Content-Type': 'application/json',
            accept: 'application/json',
        });
        const url = `${this.contentService.url}umbraco/api/DictionaryApi/GetAllLanguages`;

        const domainLanguageMap: { [key: string]: string[] } = {
            com: ['en-US', 'fi-FI', 'sv-FI', 'lv-LV', 'et-EE', 'lt-LT'],
            fi: ['fi-FI', 'sv-FI', 'en-US'],
            lv: ['lv-LV', 'en-US'],
            ee: ['et-EE', 'en-US'],
            lt: ['lt-LT', 'en-US'],
        };

        this.http
            .get<any>(url, {
                headers,
            })
            .subscribe({
                next: (response) => {
                    const hostname = window.location.hostname;
                    const domainSuffix = hostname.split('.').pop();
                    const defaultSuffix = environment.production ? 'fi' : 'com';

                    const availableLanguages =
                        domainSuffix && Object.keys(domainLanguageMap).includes(domainSuffix)
                            ? domainLanguageMap[domainSuffix]
                            : domainLanguageMap[defaultSuffix];

                    const filteredResponse = response.filter((language: Language) =>
                        availableLanguages.includes(language.isoCode),
                    );

                    this.languages$.next(filteredResponse);
                },
            });
    }

    transformUmbracoFieldKeys(input: { [key: string]: any }): { [key: string]: any } {
        const result: { [key: string]: any } = {};
        const fieldObject: { [key: string]: any } = {};

        for (const key in input) {
            if (input.hasOwnProperty(key)) {
                if (key.startsWith('field.')) {
                    const subKey = key.split('field.')[1];
                    fieldObject[subKey] = input[key];
                } else {
                    result[key] = input[key];
                }
            }
        }

        if (Object.keys(fieldObject).length > 0) {
            result['field'] = fieldObject;
        }

        return result;
    }
}
