import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';

import { environment } from '../../environment/environment';

import { ContentService } from './services/content.service';

export class UmbracoTranslateLoader implements TranslateLoader {
    constructor(
        private http: HttpClient,
        private contentService: ContentService,
    ) {}

    getTranslation(isoCode: string): Observable<Object> {
        const params = new HttpParams({
            fromObject: {
                isoCode,
            },
        });
        const headers = new HttpHeaders({
            ApiKey: `${environment.apiKey}`,
            'Content-Type': 'application/json',
            accept: 'application/json',
        });
        const url = `${this.contentService.url}umbraco/api/DictionaryApi/GetDictionaryItems`;

        const staticTranslations$ = this.http.get(`../common/i18n/${isoCode}.json`);

        const backendTranslations$ = this.http
            .get<any>(url, {
                headers,
                params,
            })
            .pipe(map((result) => JSON.parse(result)));

        return forkJoin([staticTranslations$, backendTranslations$]).pipe(
            switchMap((combinedResults) => {
                let translations: Object = {};

                combinedResults.forEach((result) => (translations = Object.assign(translations, result)));

                return of(translations);
            }),
        );
    }
}
