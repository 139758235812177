import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { DataViewModule } from 'primeng/dataview';
import { TagModule } from 'primeng/tag';
import { ButtonModule } from 'primeng/button';
import { SkeletonModule } from 'primeng/skeleton';
import { ImageModule } from 'primeng/image';

import { HeroCampaignComponent } from '../hero-campaign/hero-campaign.component';
import { FilterComponent } from '../filter/filter.component';
import { CarTileComponent } from '../car-tile/car-tile.component';
import { CopService } from '../../services/cop.service';
import { AvailableModelsResponse } from '../../../../../common/models/available-models-response';
import { CampaignCodeButtonComponent } from '../campaign-code-button/campaign-code-button.component';

@Component({
    selector: 'app-landing-page',
    standalone: true,
    imports: [
        CommonModule,
        DataViewModule,
        TagModule,
        ButtonModule,
        HeroCampaignComponent,
        FilterComponent,
        CarTileComponent,
        TranslateModule,
        SkeletonModule,
        CampaignCodeButtonComponent,
        ImageModule,
    ],
    templateUrl: './landing-page.component.html',
    styleUrl: './landing-page.component.scss',
})
export class LandingPageComponent implements OnInit {
    sortField = 'name';
    sortOrder = 'asc';
    availableModels$: Observable<AvailableModelsResponse | undefined> = this.copService.availableModels$;
    loading$ = this.copService.loading$;
    quickFilter: string | undefined;
    vehiclesPerPage = 9;
    first = 0;

    constructor(private copService: CopService) {}

    ngOnInit() {
        this.copService.getAvailableModels();
    }

    setQuickFilter(filterType: string): void {
        this.quickFilter = filterType;
    }

    onPageChange(event: any): void {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}
