<div class="field" style="min-width: 180px">
    <label class="block" [htmlFor]="name">{{ getFieldLabel() }}{{ required ? '*' : '' }}</label>
    <p-dropdown
        ariaLabel="{{ getFieldLabel() }}"
        class="block"
        [options]="options"
        [formControl]="formControl"
        optionValue="id"
        optionLabel="label"
        [showClear]="true"
        [style]="{ height: '40px', width: width ? 'width: ' + width + 'px;' : '100%' }"
    >
        <ng-template pTemplate="selectedItem" let-option>
            {{ option.label | translate }}
        </ng-template>
        <ng-template pTemplate="item" let-option>
            {{ option.label | translate }}
        </ng-template>
    </p-dropdown>
    <app-validation-errors [control]="formControl"></app-validation-errors>
</div>
