import { Injectable } from '@angular/core';
import { environment } from '../../../environment/environment';

@Injectable({
    providedIn: 'root',
})
export class SplunkService {
    constructor() {}

    setSplunkScript() {
        if (!environment.production) { // TO REPLACE when we are ready to use splunk in production
            const splunkRumScript = document.createElement('script');
            splunkRumScript.src = 'https://cdn.signalfx.com/o11y-gdi-rum/v0.20.0/splunk-otel-web.js';
            splunkRumScript.crossOrigin = 'anonymous';
            splunkRumScript.onload = () => {
                if ((window as any).SplunkRum && typeof (window as any).SplunkRum.init === 'function') {
                    (window as any).SplunkRum.init({
                        realm: 'eu0',
                        rumAccessToken: 'Oa9MF9jSbYXYn_vczOED2g',
                        applicationName: 'Hyundai COP',
                        deploymentEnvironment: 'Prod',
                    });
                } else {
                    console.error('SplunkRum is not available after the script has loaded.');
                }
            };
            document.head.appendChild(splunkRumScript);

            const splunkSessionScript = document.createElement('script');
            splunkSessionScript.src =
                'https://cdn.signalfx.com/o11y-gdi-rum/v0.20.0/splunk-otel-web-session-recorder.js';
            splunkSessionScript.crossOrigin = 'anonymous';
            splunkSessionScript.onload = () => {
                if (
                    (window as any).SplunkSessionRecorder &&
                    typeof (window as any).SplunkSessionRecorder.init === 'function'
                ) {
                    (window as any).SplunkSessionRecorder.init({
                        app: 'Hyundai COP',
                        realm: 'eu0',
                        rumAccessToken: 'Oa9MF9jSbYXYn_vczOED2g',
                    });
                } else {
                    console.error('SplunkSessionRecorder is not available after the script has loaded.');
                }
            };
            document.head.appendChild(splunkSessionScript);
        }
    }
}
