<ng-container *ngIf="campaigns$ | async as campaigns">
    <ng-container *ngIf="campaigns.length === 1">
        <button
            pButton
            icon="pi fa-solid fa-tag"
            severity="primary"
            label="{{ campaigns[0].title || '' }}"
            (click)="goToCampaign(campaigns[0].link)"
            class="campaign-button"
            [ngStyle]="{
                'background-color': campaignColor,
                'color': campaigns[0].campaignTextColor || campaignTextColor
            }"
        ></button>
    </ng-container>
    <ng-container *ngIf="campaigns.length > 1">
        <button
            pButton
            icon="pi fa-solid fa-tag"
            severity="primary"
            label="{{ 'Multiple campaigns' | translate }}"
            class="campaign-button"
            [ngStyle]="{ 'background-color': campaignColor, 'color': campaignTextColor }"
            disabled
        ></button>
    </ng-container>
</ng-container>

<ng-template #placeholder>
    <div class="button-placeholder"></div>
</ng-template>
