import { Injectable } from '@angular/core';

import { environment } from '../../environment/environment';

import { CampaignResponse } from '../../../common/models/umbraco-responses/campaign-response';
import { Campaign } from '../../../common/models/campaign';
import { ContactForm } from '../../../common/models/contact-form';
import { Lead } from '../../../common/models/lead';
import { BrandService } from './services/brand.service';
import { AvailableModel } from '../../../common/models/available-model';
import { FinancingDetails } from '../../../common/models/financing-details';
import { Dealer } from '../../../common/models/dealer';
import { DateTimeService } from './services/datetime.service';

@Injectable({
    providedIn: 'root',
})
export class ContentProcessor {
    constructor(private brandService: BrandService, private dateTimeService: DateTimeService) {}

    processCampaignData(campaignResponses: CampaignResponse[]) {
        const campaigns: Campaign[] = [];

        campaignResponses.forEach((campaignResponse) => {
            campaigns.push({
                title: campaignResponse.properties.title,
                description: campaignResponse.properties.description,
                id: campaignResponse.id,
                link:
                    campaignResponse.properties.link && campaignResponse.properties.link.length
                        ? campaignResponse.properties.link[0].url
                        : '',
                start: campaignResponse.properties.start ? new Date(campaignResponse.properties.start) : null,
                end: campaignResponse.properties.end ? new Date(campaignResponse.properties.end) : null,
                imageUrl:
                    campaignResponse.properties.image && campaignResponse.properties.image.length
                        ? `${environment.apiUrl}${campaignResponse.properties.image[0].url}`
                        : '',
                code: campaignResponse.properties.campaignCode,
            });
        });

        return campaigns;
    }

    constructLead(
        contactForm: ContactForm,
        vehicle: AvailableModel,
        campaignsDetails: Campaign[],
        financingDetails: FinancingDetails,
        dealer: Dealer,
        vehicleUrl: string,
    ): Lead {
        return {
            LeadID: `fi-${this.brandService.brandNameString.toLowerCase()}`,
            LeadType: 'AskOffer',
            CreatedAt: this.dateTimeService.getCurrentUTCTimeFormatted(),
            UpdatedAt: this.dateTimeService.getCurrentUTCTimeFormatted(),
            BuyerType: '',
            UserData: {
                FirstName: contactForm.firstName,
                LastName: contactForm.lastName,
                FullName: `${contactForm.firstName} ${contactForm.lastName}`,
                Phone: contactForm.phone,
                Email: contactForm.email,
                PersonalIdentificationNumber: '',
                Postal: contactForm.postalCode,
                Address: contactForm.address,
                City: contactForm.city,
                PreferredContactMethod: contactForm.preferredContactMethod,
                HasAcceptedTerms: contactForm.privacyPolicyAgreement[0] === 'true' || false,
            },
            VerifiedProfileData: '',
            CarData: {
                Name: `${vehicle.Make} ${vehicle.ModelSeries} ${vehicle.ModelName}`,
                Image: vehicle.PicUrl,
                Make: vehicle.Make,
                Model: vehicle.ModelSeries,
                Url: vehicleUrl,
                Vin: '',
                OriginalPrice: parseFloat(vehicle.Vehicle_Total_Price_Incl_Vat),
                DiscountedPrice: 0,
                EstimatedCarTax: parseFloat(vehicle.Vehicle_Car_Tax),
                DeliveryCost: parseFloat(vehicle.Delivery_Cost),
                FinalPrice: parseFloat(vehicle.Vehicle_Total_Retail_Price) + parseFloat(vehicle.Delivery_Cost),
                Color: vehicle.ColorName,
                IsBooked: 'No',
                CampaignText: campaignsDetails.map(c => `${c.code},${c.description}`).join(','),
            },
            Options: [],
            TradeIn: '',
            TradeInCarData: {
                RegistrationNumber: '',
                Model: '',
                Motor: '',
                Gearbox: '',
                BodyType: '',
                FirstRegister: '',
                Mileage: '',
                SaleLink: '',
                Comment: '',
                Files: '',
            },
            FinanceOption: '',
            LeaseOption: '',
            Calculator: [
                {
                    LeaseInPercentage: financingDetails.leaseInPercentage,
                    LeaseSplitPercentage: financingDetails.leaseSplitPercentage,
                    LeaseType: 'PartPayment',
                    PriceWithDelivery: financingDetails.priceWithDelivery,
                    FinanceRequired: financingDetails.financeRequired,
                    LeaseIn: financingDetails.leaseIn,
                    LeaseSplit: financingDetails.leaseSplit,
                    LeaseMonths: financingDetails.leaseMonths,
                    LeaseIntrest: financingDetails.leaseIntrest,
                    EstablishmentFee: financingDetails.establishmentFee,
                    ProcessingFee: financingDetails.processingFee,
                    MonthlyPayment: financingDetails.monthlyPayment,
                    Expenses: financingDetails.expenses,
                },
            ],
            DealerLocation: {
                LocationID: parseInt(dealer.id),
                DealerCode: dealer.WCustomerNumber,
                Name: dealer.label,
                Address: `${dealer.streetAddress}, ${dealer.postalCode}`,
                Phone: dealer.salesPhone,
                SalesEmail: dealer.salesEmail,
            },
            ExtraInfo: contactForm.additionalInformation,
        };
    }
}
