<div class="field">
    <label [htmlFor]="name" [id]="name">{{ getFieldLabel() }}{{ required ? '*' : '' }}</label>
    <div *ngFor="let option of options" class="field-checkbox">
        <p-radioButton [inputId]="option.id" [value]="option.id" [formControl]="formControl" />
        <label [for]="option.id" class="ml-2">
            {{ option.label }}
        </label>
    </div>
    <app-validation-errors [control]="formControl"></app-validation-errors>
</div>
