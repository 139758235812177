import { DateTime } from 'luxon';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class HelperService {
    private readonly TRANSLATION_CODE_MAP: { [key: string]: string } = {
        fi: 'FIN',
        lt: 'LIT',
        lv: 'LAT',
        ee: 'EST',
    };

    constructor(private translateService: TranslateService) {}

    formatCurrency(value: number | null | undefined, maximumFractionDigits = 2) {
        if (typeof value === 'undefined' || value === null) {
            return '-';
        } else {
            return new Intl.NumberFormat('fi-FI', {
                style: 'currency',
                currency: 'EUR',
                maximumFractionDigits,
            }).format(value);
        }
    }

    formatNumber(value: number | null | undefined) {
        if (typeof value === 'undefined' || value === null) {
            return '-';
        } else {
            return new Intl.NumberFormat('fi-FI').format(value);
        }
    }

    formatToFinnishDate(value: Date | null | undefined) {
        if (typeof value === 'undefined' || value === null) {
            return '-';
        } else {
            return new Intl.DateTimeFormat('fi-FI').format(value);
        }
    }

    sortAlphabetically(sortProperty: string) {
        return (itemA: any, itemB: any) => {
            const nameA = itemA[sortProperty].toLowerCase();
            const nameB = itemB[sortProperty].toLowerCase();

            return nameA.localeCompare(nameB);
        };
    }

    convertLocalDateToUtc(date: Date): Date {
        const utcOffset = DateTime.fromJSDate(date).offset;
        const utcStartOfDay = DateTime.fromJSDate(date, { zone: 'UTC' }).plus({ minutes: utcOffset }).startOf('day');

        return utcStartOfDay.toJSDate();
    }

    get currentLanguage(): string {
        return this.translateService.currentLang || this.translateService.defaultLang;
    }

    public extractTranslation(input: string, languageKey: string): string;
    public extractTranslation(input: string[], languageKey: string): string[];

    public extractTranslation(input: string | string[], languageKey: string): string | string[] {
        if (Array.isArray(input)) {
            return input.map(item => this.extractTranslationFromString(item, languageKey));
        }
        return this.extractTranslationFromString(input, languageKey);
    }
    /**
     * Extracts the translation text for the given input based on the language key.
     *
     * The input is expected to be in the format:
     *   "{Default translation} ; ENG {English translation} ; EST {Estonian translation} ; LAT {Latvian translation} ; LIT {Lithuanian translation}"
     *
     * The default (first) segment is assumed to be the Finnish translation. If a segment for the target
     * language is found (based on the mapping), its inner text is returned.
     *
     * @param input - The full translation string (e.g. vehicle.ColorName)
     * @param languageKey The FE language key (e.g. 'fi', 'ee', 'lv', or 'lt').
     * @returns The translation text for the target language (or default if not found)
     */
    extractTranslationFromString(input: string, languageKey: string): string {
        const targetLangCode = this.TRANSLATION_CODE_MAP[languageKey] || '';
        if (!input) {
            return '';
        }

        // Split the string into segments using ';' and trim each segment.
        const parts = input.split(';').map((part) => part.trim());

        // Use the first segment as the default translation.
        let defaultTranslation = '';
        if (parts.length > 0) {
            // Allow any character (including line breaks) inside the curly braces.
            const defaultMatch = parts[0].match(/^\{([\s\S]+?)\}$/);
            defaultTranslation = defaultMatch ? defaultMatch[1].trim() : parts[0];
        }

        // Updated regex:
        //   ^([A-Z]+)\s*\{([\s\S]+?)\}\s*$
        // This matches a language code (one or more letters), optional whitespace,
        // an opening '{', then lazily captures any characters (including newlines)
        // until the closing '}', and finally optional trailing whitespace.
        const regex = /^([A-Z]+)\s*\{([\s\S]+?)\}\s*$/i;

        // Iterate over each segment and try to match the target language code.
        for (const part of parts) {
            const match = part.match(regex);
            if (match && match[1].trim().toUpperCase() === targetLangCode.toUpperCase()) {
                return match[2].trim();
            }
        }
        // Fallback: if no matching segment is found, return the default.
        return defaultTranslation;
    }
}
