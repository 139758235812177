import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class DateTimeService {

  constructor() {}

  // Convert current Finnish time to UTC and format it
  getCurrentUTCTimeFormatted(format: string = 'yyyy-MM-dd HH:mm:ss'): string {
    const finnishTime = DateTime.now().setZone('Europe/Helsinki');
    const utcTime = finnishTime.toUTC();
    return utcTime.toFormat(format);
  }

  // Convert a given DateTime to UTC and format it
  convertToUTC(dateTime: DateTime, format: string = 'yyyy-MM-dd HH:mm:ss'): string {
    const utcTime = dateTime.toUTC();
    return utcTime.toFormat(format);
  }

  // Convert a given DateTime to Finnish time and format it
  convertToFinnishTime(dateTime: DateTime, format: string = 'yyyy-MM-dd HH:mm:ss'): string {
    const finnishTime = dateTime.setZone('Europe/Helsinki');
    return finnishTime.toFormat(format);
  }

  // Get the current time in Finnish time and format it
  getCurrentFinnishTimeFormatted(format: string = 'yyyy-MM-dd HH:mm:ss'): string {
    const finnishTime = DateTime.now().setZone('Europe/Helsinki');
    return finnishTime.toFormat(format);
  }

  formatDate(date: Date | null): string {
    if (date === null) return '';
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
}