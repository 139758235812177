import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LowerCasePipe, NgClass, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { ImageModule } from 'primeng/image';

import { HelperService } from '../../../framework/services/helper.service';
import { AvailableModel } from '../../../../../common/models/available-model';
import { CampaignCodeButtonComponent } from '../campaign-code-button/campaign-code-button.component';
import { BrandService } from '../../services/brand.service';

@Component({
    selector: 'app-car-tile',
    standalone: true,
    imports: [ButtonModule, TranslateModule, RouterLink, NgIf, ImageModule, CampaignCodeButtonComponent, NgClass, LowerCasePipe],
    templateUrl: './car-tile.component.html',
    styleUrl: './car-tile.component.scss',
})
export class CarTileComponent {
    @Input() vehicle: AvailableModel | undefined;

    constructor(private helperService: HelperService, public brandService: BrandService) {}

    formatCurrencyValue(value: string | null, maximumFractionDigits = 2) {
        return this.helperService.formatCurrency(parseFloat(value || '0'), maximumFractionDigits);
    }

    getTranslatedLocationName(): string {
        if (!this.vehicle) {
          return '';
        }
        return this.helperService.extractTranslation(this.vehicle.LocationName, this.brandService.languageKey);
      }

    get brandNameString(): string {
        return this.brandService.brandNameString;
    }
    
}
