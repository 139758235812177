import { Component, ElementRef, ViewChild } from '@angular/core';
import { NgClass } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { TranslateService, TranslateModule } from '@ngx-translate/core';

import { LayoutService } from './service/app.layout.service';
import { BrandService } from '../../application/services/brand.service';
import { DropdownModule } from 'primeng/dropdown';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    standalone: true,
    imports: [RouterLink, NgClass, TranslateModule, DropdownModule],
})
export class AppTopBarComponent {
    items!: MenuItem[];
    // dropDownItems: MenuItem[] = [];

    // languageOptions: { code: string; flagCode: string; name: string }[] = [
    //     {
    //         code: 'fi-FI',
    //         flagCode: 'fi',
    //         name: 'Suomi',
    //     },
    //     {
    //         code: 'se-SV',
    //         flagCode: 'se',
    //         name: 'Svenska',
    //     },
    //     {
    //         code: 'en-US',
    //         flagCode: 'gb',
    //         name: 'English',
    //     },
    // ];

    @ViewChild('menuButton') menuButton!: ElementRef;
    @ViewChild('topBarMenuButton') topbarMenuButton!: ElementRef;
    @ViewChild('topBarMenu') menu!: ElementRef;

    constructor(
        public layoutService: LayoutService,
        private brandService: BrandService,
        private translateService: TranslateService,
        public router: Router,
    ) {}

    // changeLanguage(languageCode: string): void {
    //     this.translateService.use(languageCode);
    // }

    // get currentLanguage(): string {
    //     return this.translateService.currentLang;
    // }

    get brandLogoDark(): string {
        return this.brandService.getBrandLogo('dark');
    }

    get brandLogoAlt(): string {
        return this.translateService.instant('brandLogoAlt', this.brandService.brandNameString);
    }
}
