import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Output, EventEmitter } from '@angular/core';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { InputNumberModule } from 'primeng/inputnumber';
import { SliderModule } from 'primeng/slider';

import { HelperService } from '../../../framework/services/helper.service';
import { AvailableModel } from '../../../../../common/models/available-model';
import { FinancingDetails } from '../../../../../common/models/financing-details';
import { CopService } from '../../services/cop.service';
import { Subject, takeUntil } from 'rxjs';
import { CopBrandingResponse } from '../../../../../common/models/umbraco-responses/cop-branding-response';

@Component({
    selector: 'app-financing-calculator',
    standalone: true,
    imports: [FormsModule, NgIf, TranslateModule, InputNumberModule, SliderModule],
    templateUrl: './financing-calculator.component.html',
    styleUrl: './financing-calculator.component.scss',
})
export class FinancingCalculatorComponent implements OnInit, OnDestroy {
    @Input() vehicle!: AvailableModel;
    @Output() financingDetailsEvent = new EventEmitter<FinancingDetails>();

    financingDetails: FinancingDetails = {
        leaseIn: 0,
        leaseInPercentage: 10,
        leaseSplit: 0,
        leaseSplitPercentage: 20,
        priceWithDelivery: 0,
        financeRequired: 0,
        leaseMonths: 48,
        leaseIntrest: 0,
        establishmentFee: 0,
        processingFee: 0,
        monthlyPayment: 0,
        expenses: 0,
    };

    vehiclePriceExclAccessories = 0;
    accessoriesPrice = 0;
    carTax = 0;
    deliveryFee = 0;

    financingPeriodMax = 60;
    leaseSplitPercentageMax = 50;
    financingPeriodMin = 1;

    copBrand$ = this.copService.copBrand$;

    private onDestroy = new Subject<void>();

    constructor(
        private helperService: HelperService,
        private copService: CopService,
    ) {}

    ngOnInit() {
        if (!this.vehicle) {
            return;
        }

        this.copBrand$.pipe(takeUntil(this.onDestroy)).subscribe({
            next: (copBrand: CopBrandingResponse) => {
                if (Object.keys(copBrand).length === 0) {
                    return;
                }

                this.initFinancingDetails(copBrand);
                this.updateFinancingDetails();
            },
        });
    }

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    formatCurrencyValue(value: number | null, maximumFractionDigits = 2) {
        return this.helperService.formatCurrency(value, maximumFractionDigits);
    }

    formatNumberValue(value: number | null) {
        return this.helperService.formatNumber(value);
    }

    pmt(type: number = 0): number {
        const rate = this.financingDetails.leaseIntrest / 100 / 12;
        const nper = this.financingDetails.leaseMonths;
        const pv = this.totalLoanAmount;
        const fv = this.financingDetails.leaseSplit;

        if (rate === 0) {
            return -(pv + fv) / nper;
        }

        const pvif = Math.pow(1 + rate, nper);
        let pmt = (rate * (pv * pvif - fv)) / (pvif - 1);

        if (type === 1) {
            pmt /= 1 + rate;
        }

        const monthlyPayment = parseFloat(pmt.toFixed(2));
        this.financingDetails.monthlyPayment = monthlyPayment;

        return monthlyPayment;
    }

    get totalLoanAmount(): number {
        return (
            this.vehiclePriceExclAccessories +
            this.accessoriesPrice +
            this.carTax +
            this.deliveryFee +
            this.financingDetails.establishmentFee -
            this.financingDetails.leaseIn
        );
    }

    get priceWithDelivery(): number {
        if (!this.vehicle) {
            return 0;
        }

        return (
            parseFloat(this.vehicle.Vehicle_Total_Price_Incl_Vat.toString()) +
            parseFloat(this.vehicle.Delivery_Cost.toString())
        );
    }

    cashChange(): void {
        this.financingDetails.leaseInPercentage = parseFloat(
            ((this.financingDetails.leaseIn / this.vehiclePriceExclAccessories) * 100).toFixed(2),
        );

        this.updateFinancingDetails();
    }

    deviatingLastInstallmentChange(): void {
        this.financingDetails.leaseSplitPercentage = parseFloat(
            ((this.financingDetails.leaseSplit / this.vehiclePriceExclAccessories) * 100).toFixed(0),
        );

        this.updateFinancingDetails();
    }

    deviatingLastInstallmentPercentageChange(value: number): void {
        if (value > this.leaseSplitPercentageMax) {
            this.financingDetails.leaseSplitPercentage = this.leaseSplitPercentageMax;
        }

        this.financingDetails.leaseSplit =
            (this.financingDetails.leaseSplitPercentage / 100) * this.vehiclePriceExclAccessories;

        this.updateFinancingDetails();
    }

    updateFinancingDetails(): void {
        this.financingDetails.financeRequired = this.totalLoanAmount;
        this.financingDetails.monthlyPayment = this.pmt();
        this.financingDetails.priceWithDelivery = this.priceWithDelivery;
        this.financingDetails.expenses = this.financingDetails.monthlyPayment + this.financingDetails.processingFee;

        this.financingDetailsEvent.emit(this.financingDetails);
    }

    private initFinancingDetails(copBrand: CopBrandingResponse): void {
        this.financingDetails.processingFee = copBrand.properties.monthlyProcessingFee;
        this.financingDetails.establishmentFee = copBrand.properties.establishmentFee;
        this.financingDetails.leaseIntrest = copBrand.properties.financialInterestPercentage;
        this.financingPeriodMax = copBrand.properties.financingPeriodMax;
        this.leaseSplitPercentageMax = copBrand.properties.maxPercentagePeriodOfLastInstallment;

        this.vehiclePriceExclAccessories = parseFloat(this.vehicle.Vehicle_Total_Price_Incl_Vat.toString());
        this.carTax = parseFloat(this.vehicle.Vehicle_Car_Tax.toString());
        this.deliveryFee = parseFloat(this.vehicle.Delivery_Cost ? this.vehicle.Delivery_Cost.toString() : '0');
        this.financingDetails.leaseIn = this.vehiclePriceExclAccessories * 0.1;
        this.financingDetails.leaseSplit = this.vehiclePriceExclAccessories * 0.2;
    }
}
