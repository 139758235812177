import { Component, Input, OnInit } from '@angular/core';
import { AsyncPipe, CommonModule, NgIf } from '@angular/common';
import { Observable } from 'rxjs';
import { ButtonModule } from 'primeng/button';

import { AvailableModel } from '../../../../../common/models/available-model';
import { CopService } from '../../services/cop.service';
import { Campaign } from '../../../../../common/models/campaign';
import { CopBrandingResponse } from 'src/common/models/umbraco-responses/cop-branding-response';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-campaign-code-button',
    standalone: true,
    imports: [ButtonModule, NgIf, AsyncPipe, CommonModule, TranslateModule],
    templateUrl: './campaign-code-button.component.html',
    styleUrl: './campaign-code-button.component.scss',
})
export class CampaignCodeButtonComponent implements OnInit {
    @Input() vehicle!: AvailableModel;
    campaigns$: Observable<Campaign[]> | undefined;
    copBrand$ = this.copService.copBrand$;
    campaignColor: string | undefined;
    campaignTextColor: string | undefined;

    constructor(private copService: CopService) {}

    ngOnInit() {
        if (!this.vehicle.CampaignCode) {
            return;
        }

        this.copService.copBrand$.subscribe({
            next: (copBrand: CopBrandingResponse) => {
                if (Object.keys(copBrand).length === 0) {
                    return;
                }
                this.campaignColor = copBrand.properties.campaignColor;
                this.campaignTextColor = copBrand.properties.campaignTextColor;
            }
        });

        const campaignCodes = this.vehicle.CampaignCode.split(',').map(code => code.trim());
        this.campaigns$ = this.copService.getCampaignsByCodes(campaignCodes);
    }

    goToCampaign(url: string) {
        window.open(url, '_blank');
    }
}
