<div class="grid gap-2">
    <div class="col-12">
        <h1 class="text-3xl">{{ 'Vehicle Page Title' | translate }}</h1>
    </div>
    <ng-container>
        <div
            class="col-12 flex px-0 sm:px-2 justify-content-between flex-wrap md:flex-nowrap"
            *ngIf="vehicle; else skeletons"
        >
            <div class="col-12 md:col-6 flex flex-column">
                <div class="col" *ngIf="(campaigns$ | async)?.length ?? 0 > 0" #campaignAccordionTab>
                    <p-accordion class="w-full" expandIcon="pi pi-plus" collapseIcon="pi pi-minus">
                        <p-accordionTab iconPos="end" id="campaignAccordionTab">
                            <ng-template pTemplate="header">
                                <div class="flex align-items-center gap-2 w-full">
                                    <span class="font-bold white-space-nowrap">
                                        <ng-container *ngIf="campaigns$ | async as campaigns">
                                            <ng-container *ngIf="campaigns.length > 1; else singleCampaign">
                                                {{ 'Multiple campaigns' | translate }}
                                            </ng-container>
                                            <ng-template #singleCampaign>
                                                <ng-container *ngIf="campaigns[0].link; else plainTitle">
                                                    <a
                                                        [href]="campaigns[0].link"
                                                        target="_blank"
                                                        class="campaign-title-link text-decoration-none"
                                                        [ngStyle]="{'color': currentTextColor}"
                                                        >{{ campaigns[0].title }}</a
                                                    >
                                                </ng-container>
                                                <ng-template #plainTitle>
                                                    {{ campaigns[0].title }}
                                                </ng-template>
                                            </ng-template>
                                        </ng-container>
                                    </span>
                                </div>
                            </ng-template>
                            <div *ngIf="campaigns$ | async as campaigns">
                                <div *ngFor="let campaign of campaigns">
                                    <b>
                                        <ng-container *ngIf="campaign.link; else plainCampaignTitle">
                                            <a [href]="campaign.link" target="_blank" class="text-decoration-none">{{ campaign.title }}</a>
                                        </ng-container>
                                        <ng-template #plainCampaignTitle>
                                            {{ campaign.title }}
                                        </ng-template>
                                    </b>
                                    <p>{{ campaign.description }}</p>
                                    <p *ngIf="campaign.end">
                                        {{ 'End Date' | translate }}: {{ getFormattedEndDate(campaign.end) }}
                                    </p>
                                    <br />
                                </div>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                </div>
                <div class="col-12 flex flex-column">
                    <div
                        class="col-12 bg-no-repeat bg-center h-22rem md:h-30rem bg-contain"
                        [ngStyle]="{ 'background-image': 'url(' + vehicle.PicUrl + ')' }"
                    ></div>
                    <span class="text-sm">{{ 'Vehicle Picture Asterisk Text' | translate }}</span>
                </div>
                <div class="col-12">
                    <h2 class="text-2xl font-bold pt-2">{{ vehicle.ModelSeries }} {{ vehicle.ModelName }}</h2>
                </div>
                <div class="col-12 flex flex-wrap sm:gap-4 justify-content-space-between">
                    <div class="col-6 sm:col-4 xl:col-3 p-0 flex">
                        <div class="col-4 align-content-center text-primary">
                            <i class="pi fa-solid fa-car-side mr-2 text-3xl"></i>
                        </div>
                        <div class="col-8" [ngClass]="(brandNameString | lowercase) + '-text-color'">
                            <div class="text-sm">{{ 'Size' | translate }}</div>
                            <div class="text-xl font-bold">{{ 'Body Type ' + vehicle.ModelBodyType | translate }}</div>
                        </div>
                    </div>
                    <div class="col-6 sm:col-4 xl:col-3 p-0 flex">
                        <div class="col-4 align-content-center text-primary">
                            <i class="pi fa-solid fa-truck-monster mr-2 text-3xl"></i>
                        </div>
                        <div class="col-8" [ngClass]="(brandNameString | lowercase) + '-text-color'">
                            <div class="text-sm">{{ 'Drive Type' | translate }}</div>
                            <div class="text-xl font-bold">
                                {{ 'Drive Type ' + vehicle.ModelDriveType | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="col-6 sm:col-4 xl:col-3 p-0 flex">
                        <div class="col-4 align-content-center text-primary">
                            <i
                                *ngIf="vehicle.ModelDrivingForce !== 'EL'"
                                class="pi fa-solid fa-gas-pump mr-2 text-3xl"
                            ></i>
                            <i
                                *ngIf="vehicle.ModelDrivingForce === 'EL'"
                                class="pi fa-solid fa-charging-station mr-2 text-3xl"
                            ></i>
                        </div>
                        <div class="col-8" [ngClass]="(brandNameString | lowercase) + '-text-color'">
                            <div class="text-sm">{{ 'Driving Force' | translate }}</div>
                            <div class="text-xl font-bold">
                                {{ 'Driving Force ' + vehicle.ModelDrivingForce | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="col-6 sm:col-4 xl:col-3 p-0 flex" *ngIf="vehicle.ModelDrivingForceAlternative !== ''">
                        <div class="col-4 align-content-center text-primary">
                            <i class="pi fa-solid fa-charging-station mr-2 text-3xl"></i>
                        </div>
                        <div class="col-8" [ngClass]="(brandNameString | lowercase) + '-text-color'">
                            <div class="text-sm">{{ 'Hybrid Type' | translate }}</div>
                            <div class="text-xl font-bold">
                                {{ 'Hybrid Type ' + vehicle.DrivingForceOption | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="col-6 sm:col-4 xl:col-3 p-0 flex">
                        <div class="col-4 align-content-center text-primary">
                            <i class="pi fa-solid fa-tape mr-2 text-3xl"></i>
                        </div>
                        <div class="col-8" [ngClass]="(brandNameString | lowercase) + '-text-color'">
                            <div class="text-sm">{{ 'Transmission Type' | translate }}</div>
                            <div class="text-xl font-bold">
                                {{ 'Transmission Type ' + vehicle.ModelTransmission | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="col-6 sm:col-4 xl:col-3 p-0 flex">
                        <div class="col-4 align-content-center text-primary">
                            <i class="pi fa-solid fa-cloud mr-2 text-3xl"></i>
                        </div>
                        <div class="col-8" [ngClass]="(brandNameString | lowercase) + '-text-color'">
                            <div class="text-sm">CO<sub>2</sub></div>
                            <div class="text-xl font-bold">
                                {{ parseInteger(vehicle.Co2CombinedWLTP) }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 flex flex-wrap">
                    <div class="col">
                        <button
                            pButton
                            icon="pi fa-solid fa-chevron-right"
                            iconPos="right"
                            (click)="goToContactForm()"
                            label="{{ 'Button Request Offer For This Vehicle' | translate }}"
                        ></button>
                    </div>
                    <div class="col">
                        <button
                            pButton
                            icon="pi fa-solid fa-chevron-right"
                            iconPos="right"
                            [routerLink]="['../']"
                            label="{{ 'Button Back To Listing' | translate }}"
                        ></button>
                    </div>
                </div>
                <div class="col-12 flex justify-content-between py-0">
                    <div class="col align-content-center pl-0">
                        <h3 class="col text-2xl font-bold pb-0 mb-0">{{ 'Label Price Starting At' | translate }}</h3>
                        <div class="col text-sm py-0">({{ 'Label Inc Vat Etc' | translate }})</div>
                    </div>
                    <div class="col pt-3 text-2xl font-bold text-right">
                        {{ formatCurrencyValue(vehicle.Vehicle_Total_Retail_Price) }}
                    </div>
                </div>
                <div class="col-12 flex justify-content-between py-0">
                    <h4 class="col text-xl font-medium mb-0">{{ 'Label Price Without Car Tax' | translate }}</h4>
                    <div class="col pt-3 text-xl font-bold text-right">
                        {{ formatCurrencyValue(vehicle.Vehicle_Total_Price_Incl_Vat) }}
                    </div>
                </div>
                <div class="col-12 flex justify-content-between py-0">
                    <h4 class="col text-xl font-medium mb-0">{{ 'Label Equipment Without Car Tax' | translate }}</h4>
                    <div class="col pt-3 text-xl font-bold text-right">
                        {{ formatCurrencyValue(vehicle.Vehicle_Accessories_Price) }}
                    </div>
                </div>
                <div class="col-12 flex justify-content-between py-0">
                    <h4 class="col text-xl font-medium mb-0">{{ 'Label Estimated Car Tax' | translate }}</h4>
                    <div class="col pt-3 text-xl font-bold text-right">
                        {{ formatCurrencyValue(vehicle.Vehicle_Car_Tax) }}
                    </div>
                </div>
                <div class="col-12 flex justify-content-between py-0">
                    <h4 class="col text-xl font-medium mb-0">{{ 'Exterior color price' | translate }}</h4>
                    <div class="col pt-3 text-xl font-bold text-right">
                        {{ formatCurrencyValue(vehicle.Vehicle_Colour_Price_Incl_Vat) }}
                    </div>
                </div>
                <div class="col-12 flex justify-content-between py-0">
                    <h4 class="col text-xl font-medium mb-0">{{ 'Decor color price' | translate }}</h4>
                    <div class="col pt-3 text-xl font-bold text-right">
                        {{ formatCurrencyValue(vehicle.Vehicle_Decor_Price_Incl_Vat) }}
                    </div>
                </div>
                <div class="col-12 flex justify-content-between py-0">
                    <h4 class="col text-xl font-medium mb-0">{{ 'Label VAT' | translate }}</h4>
                    <div class="col pt-3 text-xl font-bold text-right">
                        {{ formatCurrencyValue(vehicle.Vehicle_VAT) }}
                    </div>
                </div>
                <div class="col-12 flex justify-content-between py-0">
                    <h4 class="col text-xl font-medium mb-0">{{ 'Shipping Costs' | translate }}</h4>
                    <div class="col pt-3 text-xl font-bold text-right">
                        {{ formatCurrencyValue(vehicle.Delivery_Cost) }}
                    </div>
                </div>
                <div class="col-12 flex py-0">
                    <div class="col text-sm">*{{ 'Car Tax Asterisk Text' | translate }}</div>
                </div>
                <div class="col-12 flex justify-content-center">
                    <div class="col flex flex-column bg-khaki m-1 sm:m-4">
                        <div class="col-12">
                            <h3 class="text-2xl font-bold">{{ 'Accessories' | translate }}</h3>

                            <p-panel
                                *ngIf="vehicle.StandardAccessories !== null"
                                header="{{ 'Standard Accessories' | translate }}"
                                [toggleable]="true"
                                [collapsed]="true"
                                class="mb-3"
                            >
                                <ul>
                                    <li *ngFor="let item of vehicle.StandardAccessories">{{ item }}</li>
                                </ul>
                            </p-panel>

                            <p-panel
                                *ngIf="vehicle.AdditionalAccessories !== null"
                                header="{{ 'Additional Accessories' | translate }}"
                                [toggleable]="true"
                                [collapsed]="true"
                                class="mb-3"
                            >
                                <ul>
                                    <li *ngFor="let item of vehicle.AdditionalAccessories">{{ item }}</li>
                                </ul>
                            </p-panel>
                        </div>

                        <div class="col-12">
                            <h3 class="text-2xl font-bold">{{ 'Exterior color' | translate }}</h3>
                            <p class="font-semibold" [innerHTML]="getContentForLanguage(vehicle.ColorName)"></p>
                        </div>

                        <div class="col-12">
                            <h3 class="text-2xl font-bold">{{ 'Decor' | translate }}</h3>
                            <p class="font-semibold" [innerHTML]="getContentForLanguage(vehicle.DecorName)"></p>
                        </div>
                        <div class="col-12">
                            <h3 class="text-2xl font-bold">
                                {{ 'Title Estimated Delivery' | translate }} {{ vehicle.EstDeliveryTime }}
                                {{ 'Label Days' | translate }}
                            </h3>
                            <p class="font-semibold" [innerHTML]="vehicle.ModelDescriptionFin"></p>
                        </div>
                        <div class="col-12 pt-4">
                            <a
                                [href]="modelSerieLinkUrl"
                                target="_blank"
                                rel="noopener noreferrer"
                                class="p-button p-button-icon-right font-bold"
                            >
                                <span class="p-button-icon p-button-icon-right pi fa-solid fa-chevron-right"></span>
                                <span class="p-button-label">{{
                                    'Button Get To Know At Our Home Page' | translate: brandNamePreposition
                                }}</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-12 flex flex-wrap">
                    <div class="col">
                        <button
                            pButton
                            icon="pi fa-solid fa-chevron-right"
                            iconPos="right"
                            (click)="goToContactForm()"
                            label="{{ 'Button Request Offer For This Vehicle' | translate }}"
                        ></button>
                    </div>
                    <div class="col">
                        <button
                            pButton
                            icon="pi fa-solid fa-chevron-right"
                            iconPos="right"
                            [routerLink]="['../']"
                            label="{{ 'Button Back To Listing' | translate }}"
                        ></button>
                    </div>
                </div>
            </div>
            <div class="col-12 md:col-6" *ngIf="vehicle">
                <app-financing-calculator
                    [vehicle]="vehicle"
                    (financingDetailsEvent)="setFinancingDetails($event)"
                ></app-financing-calculator>
            </div>
        </div>
    </ng-container>
    <ng-template #skeletons>
        <div class="col-12 flex gap-2 justify-content-between flex-wrap md:flex-nowrap">
            <div class="col-12 md:col-6 flex flex-column">
                <div class="col-12 flex flex-column align-items-center">
                    <p-skeleton size="30rem"></p-skeleton>
                    <p-skeleton width="26rem" height="1rem" styleClass="mt-2"></p-skeleton>
                </div>
                <div class="col-12">
                    <h2 class="text-2xl font-bold pt-2">
                        <p-skeleton width="26rem" height="3rem"></p-skeleton>
                    </h2>
                </div>
                <div class="col-12 flex flex-wrap gap-4 text-primary">
                    <ng-container *ngFor="let i of [1, 2, 3, 4]">
                        <div class="col-4 xl:col-3 p-0 flex">
                            <div class="col-4 align-content-center">
                                <p-skeleton size="4rem" styleClass="mr-2 text-3xl"></p-skeleton>
                            </div>
                            <div class="col-8 ml-1 flex flex-column justify-content-between">
                                <div class="text-sm">
                                    <p-skeleton height="1rem"></p-skeleton>
                                </div>
                                <div class="text-xl font-bold">
                                    <p-skeleton height="2rem" styleClass="mt-1"></p-skeleton>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="col-12 flex flex-wrap">
                    <div class="col">
                        <p-skeleton width="20rem" height="3rem"></p-skeleton>
                    </div>
                    <div class="col">
                        <p-skeleton width="16rem" height="3rem"></p-skeleton>
                    </div>
                </div>
                <div class="col-12 flex justify-content-between py-0">
                    <div class="col align-content-center pl-0">
                        <h3 class="col mb-0">
                            <p-skeleton width="230px" height="40px"></p-skeleton>
                        </h3>
                        <div class="col py-0">
                            <p-skeleton width="180px" height="40px"></p-skeleton>
                        </div>
                    </div>
                    <div class="col pt-3 flex justify-content-end">
                        <p-skeleton width="12rem" height="2rem"></p-skeleton>
                    </div>
                </div>
                <div class="col-12 flex justify-content-between py-0" *ngFor="let i of [1, 2, 3, 4, 5]">
                    <h4 class="col mb-0">
                        <p-skeleton width="14rem" height="2rem"></p-skeleton>
                    </h4>
                    <div class="col pt-3 flex justify-content-end">
                        <p-skeleton width="10rem" height="1rem"></p-skeleton>
                    </div>
                </div>
                <div class="col-12 flex py-0">
                    <div class="col text-sm">
                        <p-skeleton width="40rem" height="1rem"></p-skeleton>
                    </div>
                </div>
                <div class="col-12 flex justify-content-center">
                    <div class="col flex flex-column bg-khaki m-4">
                        <div class="col-12">
                            <h3>
                                <p-skeleton width="10rem" height="2rem"></p-skeleton>
                            </h3>
                            <ul class="list-none pl-0">
                                <li class="my-2">
                                    <p-skeleton width="16rem" height="1rem"></p-skeleton>
                                </li>
                                <li class="my-2">
                                    <p-skeleton width="16rem" height="1rem"></p-skeleton>
                                </li>
                                <li class="my-2">
                                    <p-skeleton width="16rem" height="1rem"></p-skeleton>
                                </li>
                            </ul>
                        </div>
                        <div class="col-12">
                            <h3>
                                <p-skeleton width="10rem" height="2rem"></p-skeleton>
                            </h3>
                            <p>
                                <p-skeleton width="30rem" height="1rem" styleClass="mt-1"></p-skeleton>
                                <p-skeleton width="30rem" height="4rem" styleClass="mt-1"></p-skeleton>
                                <p-skeleton width="30rem" height="2rem" styleClass="mt-1"></p-skeleton>
                                <p-skeleton width="30rem" height="6rem" styleClass="mt-1"></p-skeleton>
                            </p>
                        </div>
                        <div class="col-12 pt-4">
                            <p-skeleton width="18rem" height="3rem"></p-skeleton>
                        </div>
                        <div class="col-12 flex align-items-center pt-4">
                            <p-skeleton width="20rem" height="1rem"></p-skeleton>
                        </div>
                    </div>
                </div>
                <div class="col-12 flex flex-wrap">
                    <div class="col">
                        <p-skeleton width="230px" height="40px"></p-skeleton>
                    </div>
                    <div class="col">
                        <p-skeleton width="180px" height="40px"></p-skeleton>
                    </div>
                </div>
            </div>
            <div class="col-12 md:col-6">
                <div class="flex flex-column bg-khaki p-3">
                    <div class="col-12">
                        <h3 class="text-2xl font-bold pb-0 mb-0">
                            <p-skeleton height="3rem" width="10rem"></p-skeleton>
                        </h3>
                    </div>
                    <div class="col-12 flex flex-wrap">
                        <div class="col">
                            <p-skeleton height="2rem" width="4rem"></p-skeleton>
                        </div>
                        <div class="col">
                            <p-skeleton height="2rem" width="4rem"></p-skeleton>
                        </div>
                    </div>
                    <div class="col-12 flex flex-wrap">
                        <div class="col">
                            <p-skeleton height="2rem" width="4rem"></p-skeleton>
                        </div>
                        <div class="col">
                            <p-skeleton height="2rem" width="4rem"></p-skeleton>
                        </div>
                    </div>
                    <div class="col-12">
                        <p-skeleton height="1rem" width="30rem" styleClass="my-2"></p-skeleton>
                    </div>
                    <div class="col-12 flex justify-content-between py-0">
                        <p-skeleton height="2rem" width="30rem" styleClass="my-2"></p-skeleton>
                    </div>
                    <div class="col-12 flex justify-content-between py-0">
                        <p-skeleton height="2rem" width="30rem" styleClass="my-2"></p-skeleton>
                    </div>
                    <div class="col-12 flex justify-content-between py-0">
                        <p-skeleton height="2rem" width="30rem" styleClass="my-2"></p-skeleton>
                    </div>
                    <div class="col-12 flex justify-content-between py-0">
                        <p-skeleton height="2rem" width="30rem" styleClass="my-2"></p-skeleton>
                    </div>
                    <div class="col-12 flex justify-content-between py-0">
                        <p-skeleton height="2rem" width="30rem" styleClass="my-2"></p-skeleton>
                    </div>
                    <div class="col-12 flex justify-content-between py-0">
                        <p-skeleton height="2rem" width="30rem" styleClass="my-2"></p-skeleton>
                    </div>
                    <div class="col-12 flex justify-content-between py-0">
                        <p-skeleton height="2rem" width="30rem" styleClass="my-2"></p-skeleton>
                    </div>
                    <div class="col-12 flex justify-content-between py-0">
                        <p-skeleton height="2rem" width="30rem" styleClass="my-2"></p-skeleton>
                    </div>
                    <div class="col-12">
                        <p>
                            <p-skeleton width="10rem"></p-skeleton>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>
