import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AsyncPipe, Location, NgIf, TitleCasePipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable, of, Subscription } from 'rxjs';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { MessageService } from 'primeng/api';
import { SkeletonModule } from 'primeng/skeleton';
import { ToastModule } from 'primeng/toast';

import { TextFieldComponent } from '../../../framework/components/fields/text-field/text-field.component';
import { PhoneFieldComponent } from '../../../framework/components/fields/phone-field/phone-field.component';
import { EmailFieldComponent } from '../../../framework/components/fields/email-field/email-field.component';
import { SelectFieldComponent } from '../../../framework/components/fields/select-field/select-field.component';
import { TextAreaFieldComponent } from '../../../framework/components/fields/text-area-field/text-area-field.component';
import { RadioButtonFieldComponent } from '../../../framework/components/fields/radio-button-field/radio-button-field.component';
import { CheckboxFieldComponent } from '../../../framework/components/fields/checkbox-field/checkbox-field.component';
import { ValidationErrorsComponent } from '../../../framework/components/fields/validation-errors/validation-errors.component';
import { CopService } from '../../services/cop.service';
import { AvailableModel } from '../../../../../common/models/available-model';
import { ContentProcessor } from '../../content.processor';
import { Dealer } from '../../../../../common/models/dealer';
import { FinancingDetails } from '../../../../../common/models/financing-details';
import { Campaign } from 'src/common/models/campaign';
import { LinkItem } from '../../../../../common/models/umbraco-responses/LinkItem';
import { CopBrandingResponse } from '../../../../../common/models/umbraco-responses/cop-branding-response';
import { BrandService } from '../../services/brand.service';

interface ContactFormState {
    campaignsDetails: Campaign[];
    vehicle: AvailableModel;
    financingDetails: FinancingDetails;
    vehicleUrl: string;
}

@Component({
    selector: 'app-contact-form',
    standalone: true,
    imports: [
        TranslateModule,
        ReactiveFormsModule,
        RouterLink,
        CheckboxModule,
        ToastModule,
        ButtonModule,
        TitleCasePipe,
        TextFieldComponent,
        PhoneFieldComponent,
        EmailFieldComponent,
        SelectFieldComponent,
        TextAreaFieldComponent,
        RadioButtonFieldComponent,
        CheckboxFieldComponent,
        ValidationErrorsComponent,
        CheckboxModule,
        ButtonModule,
        TitleCasePipe,
        AsyncPipe,
        NgIf,
        SkeletonModule,
    ],
    providers: [MessageService],
    templateUrl: './contact-form.component.html',
    styleUrl: './contact-form.component.scss',
})
export class ContactFormComponent implements OnInit {
    @Input()
    set id(vehicleOid: string) {
        this.vehicle$ = this.copService.getVehicle(parseInt(vehicleOid));
    }

    @Input()
    set financingCalculator(financingCalculator: any) {
        this.financingDetails = financingCalculator;
    }

    dealers$: Observable<any> | undefined;
    dealers: Dealer[] = [];
    vehicle$: Observable<AvailableModel> | undefined;
    contactMethodOptions = [
        { id: 'phone', label: '' },
        { id: 'email', label: '' },
    ];

    private langChangeSubscription: Subscription | null = null;

    contactForm!: FormGroup;
    campaignsDetails: Campaign[] | undefined;
    financingDetails: any;
    vehicle: AvailableModel | undefined;
    sendingForm = false;
    disabledSubmitButton = false;

    cookieLink: LinkItem | null = null;
    privacyPolicyLink: LinkItem | null = null;

    private vehicleUrl = '';

    constructor(
        private formBuilder: FormBuilder,
        private translateService: TranslateService,
        private messageService: MessageService,
        private copService: CopService,
        private brandService: BrandService,
        private contentProcessor: ContentProcessor,
        private location: Location,
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    ngOnInit() {
        this.updateTranslations();

        this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.updateTranslations();
        });

        const state = this.location.getState() as ContactFormState;
        this.campaignsDetails = state.campaignsDetails;
        this.vehicle = state.vehicle;
        this.financingDetails = state.financingDetails;
        this.vehicleUrl = state.vehicleUrl;

        this.contactForm = this.formBuilder.group({
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            phone: ['', [Validators.required]],
            address: [''],
            city: [''],
            postalCode: [''],
            dealer: ['', [Validators.required]],
            additionalInformation: [''],
            preferredContactMethod: ['phone'],
            privacyPolicyAgreement: [null, [Validators.required]],
        });

        this.copService.getDealers().subscribe({
            next: (result) => {
                const optionList = result.results.map((dealer) => {
                    return { id: dealer.id, label: dealer.label };
                });

                this.dealers$ = of(optionList);
                this.dealers = result.results;
            },
        });

        this.brandService.copBrand$.subscribe({
            next: (copBrand: CopBrandingResponse) => {
                if (Object.keys(copBrand).length === 0) {
                    return;
                }
                this.cookieLink = copBrand.properties.cookieLink[0];
                this.privacyPolicyLink = copBrand.properties.privacyPolicyLink[0];
            },
        });
    }

    get privacyPolicyAgreement(): FormControl {
        return this.contactForm.get('privacyPolicyAgreement') as FormControl;
    }

    submit(): void {
        this.sendingForm = true;
        this.contactForm.disable();

        const selectedDealer = this.dealers.find((dealer) => dealer.id === this.contactForm.get('dealer')!!.value)!!;

        const formattedLead = this.contentProcessor.constructLead(
            this.contactForm.value,
            this.vehicle!!,
            this.campaignsDetails!!,
            this.financingDetails,
            selectedDealer,
            this.vehicleUrl,
        );

        this.copService.sendContactForm(formattedLead).subscribe({
            next: () => {
                this.showToast(
                    'success',
                    this.translateService.instant('Summary Contact Form Submitted'),
                    this.translateService.instant('Detail Contact Form Submitted'),
                );
            },
            error: (error: any) => {
                console.error(error);
                this.sendingForm = false;
                this.showToast(
                    'error',
                    this.translateService.instant('Summary Contact Form Submitted Error'),
                    this.translateService.instant('Detail Contact Form Submitted Error'),
                );
            },
            complete: () => {
                this.sendingForm = false;
                this.disabledSubmitButton = true;

                setTimeout(() => {
                    this.router.navigate(['../'], { relativeTo: this.route });
                }, 3000);
            },
        });
    }

    showToast(severity: 'success' | 'error', summary: string, detail: string) {
        this.messageService.add({ severity, summary, detail });
    }

    updateTranslations(): void {
        this.translateService.get(['field.phone', 'field.email']).subscribe((translations) => {
            this.contactMethodOptions[0].label = translations['field.phone'];
            this.contactMethodOptions[1].label = translations['field.email'];
        });
    }

    ngOnDestroy(): void {
        if (this.langChangeSubscription) {
            this.langChangeSubscription.unsubscribe();
        }
    }
}
