import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, forkJoin, Observable, of, switchMap } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { environment } from '../../../environment/environment';
import { ContentProcessor } from '../content.processor';
import { ContentService } from './content.service';
import { BrandService } from './brand.service';
import { AvailableModel } from '../../../../common/models/available-model';
import { Campaign } from '../../../../common/models/campaign';
import { AvailableModelsResponse } from '../../../../common/models/available-models-response';
import { AvailableDealersResponse } from '../../../../common/models/available-dealers-response';
import { ModelsResponse } from '../../../../common/models/models-response';
import { CampaignResponse } from '../../../../common/models/umbraco-responses/campaign-response';
import { Lead } from '../../../../common/models/lead';
import { FILTER_TYPES } from 'src/common/models/enumeration/filter-type';

@Injectable({
    providedIn: 'root',
})
export class CopService {
    limit = 9;
    skip = 0;
    filterString = '';

    loadingAvailableModels$ = new BehaviorSubject<boolean>(true);
    dynamicFiltersLoading$ = new BehaviorSubject<boolean>(true);
    availableModels$ = new BehaviorSubject<AvailableModelsResponse>({
        pagination: {
            page: 1,
            total: 0,
        },
        results: [],
    });
    models$ = new BehaviorSubject<{ value: string; label: string }[]>([]);
    bodyTypeOptions$ = new BehaviorSubject<{ value: string; label: string }[]>([]);
    driveTypeOptions$ = new BehaviorSubject<{ value: string; label: string }[]>([]);
    drivingForceOptions$ = new BehaviorSubject<{ value: string; label: string }[]>([]);
    transmissionOptions$ = new BehaviorSubject<{ value: string; label: string }[]>([]);
    campaigns$ = new BehaviorSubject<Campaign[]>([]);

    private apiUrl = `${environment.apiUrl}/umbraco/api`;
    private copApiUrl = `${this.apiUrl}/CopApi`;
    private copApiKey = environment.copApiKey;

    constructor(
        private http: HttpClient,
        private brandService: BrandService,
        private contentService: ContentService,
        private contentProcessor: ContentProcessor,
        private translateService: TranslateService,
    ) {}

    getDealers(): Observable<{ pagination: any; results: any[] }> {
        return this.http.get<AvailableDealersResponse>(
            `${this.copApiUrl}/Dealers?brand=${this.brandService.brandNameString.toLowerCase()}&language=${this.brandService.languageKey}&skip=0&limit=100`,
            {
                headers: this.headers,
            },
        );
    }

    getAvailableModels(): void {
        this.loadingAvailableModels$.next(true);

        const availableModels: AvailableModel[] = [];

        this.http
            .get<AvailableModelsResponse>(this.getAvailableModelsUrl(), {
                headers: this.headers,
            })
            .pipe(
                switchMap((result) => {
                    availableModels.push(...result.results);
                    this.availableModels$.next(result);
                    this.loadingAvailableModels$.next(false);
                    return this.http.get<AvailableModelsResponse>(
                        this.getAvailableModelsUrl(this.limit, Math.max(result.pagination.total - this.limit, 0)),
                        {
                            headers: this.headers,
                        },
                    );
                }),
            )
            .subscribe({
                next: (result: AvailableModelsResponse) =>
                    this.availableModels$.next({
                        pagination: result.pagination,
                        results: [...availableModels, ...result.results],
                    }),
            });
    }

    getAvailableModelsUrl(skip = this.skip, limit = this.limit): string {
        let url = `${this.copApiUrl}/AvailableModels?brand=${this.brandService.brandNameString.toLowerCase()}&language=${this.brandService.languageKey}&skip=${skip}&limit=${limit}`;

        if (this.filterString.length) {
            url = url + `&${this.filterString}`;
        }

        return url;
    }

    getVehicle(vehicleOid: number): Observable<AvailableModel> {
        this.loadingAvailableModels$.next(true);
        const url = `${this.copApiUrl}/Vehicle?brand=${this.brandService.brandNameString.toLowerCase()}&language=${this.brandService.languageKey}&vehicleId=${vehicleOid}`;

        return this.http.get<AvailableModel>(url, {
            headers: this.headers,
        });
    }

    fetchDynamicFilterOptions(): void {
        this.dynamicFiltersLoading$.next(true);
        forkJoin({
            modelSerie: this.http.get<ModelsResponse>(this.getModelsUrl(FILTER_TYPES.ModelSerie), {
                headers: this.headers,
            }),
            bodyType: this.http.get<ModelsResponse>(this.getModelsUrl(FILTER_TYPES.ModelBodyType), {
                headers: this.headers,
            }),
            driveType: this.http.get<ModelsResponse>(this.getModelsUrl(FILTER_TYPES.ModelDriveType), {
                headers: this.headers,
            }),
            drivingForce: this.http.get<ModelsResponse>(this.getModelsUrl(FILTER_TYPES.ModelDrivingForce), {
                headers: this.headers,
            }),
            transmission: this.http.get<ModelsResponse>(this.getModelsUrl(FILTER_TYPES.ModelTransmission), {
                headers: this.headers,
            }),
        }).subscribe({
            next: ({ modelSerie, bodyType, driveType, drivingForce, transmission }) => {
                const modelSerieOptions = modelSerie.results.map((model) => ({
                    value: model[FILTER_TYPES.ModelSerie],
                    label: model[FILTER_TYPES.ModelSerie],
                }));

                const staticBodyTypeOptions = [
                    { label: 'Body Type AA', value: 'AA' },
                    { label: 'Body Type AB', value: 'AB' },
                    { label: 'Body Type AC', value: 'AC' },
                    { label: 'Body Type AD', value: 'AD' },
                    { label: 'Body Type AE', value: 'AE' },
                    { label: 'Body Type AF', value: 'AF' },
                    { label: 'Body Type BA', value: 'BA' },
                    { label: 'Body Type BB', value: 'BB' },
                    { label: 'Body Type SED', value: 'SED' },
                    { label: 'Body Type Hatchback', value: 'HAT' },
                    { label: 'Body Type SUV', value: 'SUV' },
                    { label: 'Body Type MPV', value: 'MPV' },
                    { label: 'Body Type LCV', value: 'LCV' },
                    { label: 'Body Type Pick-Up', value: 'PUP' },
                    { label: 'Body Type Wagon', value: 'WAG' },
                ];
                const bodyTypeOptions = bodyType.results.map((model) => {
                    const value = model[FILTER_TYPES.ModelBodyType];
                    const staticOption = staticBodyTypeOptions.find((opt) => opt.value === value);
                    return { value, label: staticOption ? staticOption.label : value };
                });

                const staticDriveTypeOptions = [
                    { label: 'Drive Type FWD', value: 'FWD' },
                    { label: 'Drive Type RWD', value: 'RWD' },
                    { label: 'Drive Type AWD', value: 'AWD' },
                ];
                const driveTypeOptions = driveType.results.map((model) => {
                    const value = model[FILTER_TYPES.ModelDriveType];
                    const staticOption = staticDriveTypeOptions.find((opt) => opt.value === value);
                    return { value, label: staticOption ? staticOption.label : value };
                });

                const staticDrivingForceOptions = [
                    { label: 'Driving Force EL', value: 'EL' },
                    { label: 'Driving Force BE', value: 'BE' },
                    { label: 'Driving Force DI', value: 'DI' },
                ];
                const drivingForceOptions = drivingForce.results.map((model) => {
                    const value = model[FILTER_TYPES.ModelDrivingForce];
                    const staticOption = staticDrivingForceOptions.find((opt) => opt.value === value);
                    return { value, label: staticOption ? staticOption.label : value };
                });

                const staticTransmissionOptions = [
                    { label: 'Transmission Type Manual', values: ['M', '2'] },
                    { label: 'Transmission Type Automatic', values: ['A', '1'] },
                ];

                const transmissionOptions = transmission.results
                    .map((model) => {
                        const value = model[FILTER_TYPES.ModelTransmission];
                        if (value === '1' || value === '2') {
                            return null;
                        }
                        const staticOption = staticTransmissionOptions.find((opt) => opt.values.includes(value));
                        return staticOption ? { value, label: staticOption.label } : { value, label: value };
                    })
                    .filter((option): option is { value: string; label: string } => option !== null);

                this.models$.next(modelSerieOptions);
                this.bodyTypeOptions$.next(bodyTypeOptions);
                this.driveTypeOptions$.next(driveTypeOptions);
                this.drivingForceOptions$.next(drivingForceOptions);
                this.transmissionOptions$.next(transmissionOptions);
                this.dynamicFiltersLoading$.next(false);
            },
            error: () => this.dynamicFiltersLoading$.next(false),
        });
    }

    getModelsUrl(filterType: string): string {
        let url = `${this.copApiUrl}/Models?brand=${this.brandService.brandNameString.toLowerCase()}&filterName=${filterType}`;

        if (this.filterString && this.filterString.length) {
            const params = this.filterString.split('&');
            let filteredParams = params.filter((param) => !param.startsWith('ModelSeries='));
            filteredParams = filteredParams.filter((param) => !param.startsWith(`${filterType}=`));

            const updatedFilterString = filteredParams.join('&');
            if (updatedFilterString) {
                url += `&${updatedFilterString}`;
            }
        }
        return url;
    }

    getCampaigns(): Observable<Campaign[]> {
        const currentBrandId = this.brandService.currentBrandId;

        if (!currentBrandId) {
            console.warn('No brand ID found. Setting campaigns to empty.');
            this.campaigns$.next([]);
            return of([]);
        }

        const currentDate = new Date().toISOString().split('T')[0];
        let campaignParams = new HttpParams({
            fromObject: {
                fetch: `descendants:${currentBrandId}`,
                fields: 'properties[$all]',
                skip: 0,
                take: 10,
            },
        });

        campaignParams = campaignParams.append('filter', 'contentType:campaign');
        campaignParams = campaignParams.append('filter', `start:${currentDate}`);
        campaignParams = campaignParams.append('filter', `end:${currentDate}`);

        return this.contentService
            .getContentItemsFromQuery<{ total: number; items: CampaignResponse[] }>(
                campaignParams,
                this.brandService.countryIsoCode || this.translateService.defaultLang,
            )
            .pipe(
                take(1),
                map(results => {
                    const processedCampaigns = this.contentProcessor.processCampaignData(results.items);
                    this.campaigns$.next(processedCampaigns);
                    return processedCampaigns;
                }),
                catchError(err => {
                    console.error('Error fetching campaigns:', err);
                    this.campaigns$.next([]);
                    return of([]);
                })
            );
    }

    getCampaignsByCodes(campaignCodes: string[]): Observable<Campaign[]> {
        return this.getCampaigns().pipe(
            map(campaigns => campaigns.filter(campaign => campaignCodes.includes(campaign.code))),
            catchError(() => of([]))
        );
    }

    sendContactForm(contactForm: Lead): Observable<any> {
        const url = `${this.apiUrl}/ContactApi/SendContactForm`;

        return this.http.post<any>(url, contactForm, {
            headers: this.headers,
        });
    }

    private get headers(): HttpHeaders {
        return new HttpHeaders({
            ApiKey: this.copApiKey,
        });
    }
}
