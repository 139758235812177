import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { filter, Observable, Subscription, take } from 'rxjs';
import { DataViewModule } from 'primeng/dataview';
import { TagModule } from 'primeng/tag';
import { ButtonModule } from 'primeng/button';
import { SkeletonModule } from 'primeng/skeleton';
import { ImageModule } from 'primeng/image';

import { HeroCampaignComponent } from '../hero-campaign/hero-campaign.component';
import { FilterComponent } from '../filter/filter.component';
import { CarTileComponent } from '../car-tile/car-tile.component';
import { CopService } from '../../services/cop.service';
import { AvailableModelsResponse } from '../../../../../common/models/available-models-response';
import { CampaignCodeButtonComponent } from '../campaign-code-button/campaign-code-button.component';
import { LinkItem } from 'src/common/models/umbraco-responses/LinkItem';

@Component({
    selector: 'app-landing-page',
    standalone: true,
    imports: [
        CommonModule,
        DataViewModule,
        TagModule,
        ButtonModule,
        HeroCampaignComponent,
        FilterComponent,
        CarTileComponent,
        TranslateModule,
        SkeletonModule,
        CampaignCodeButtonComponent,
        ImageModule,
    ],
    templateUrl: './landing-page.component.html',
    styleUrl: './landing-page.component.scss',
})
export class LandingPageComponent implements OnInit, OnDestroy {
    @ViewChild('scrollTarget') scrollTarget!: ElementRef;
    @ViewChild('scrollTargetMobile') scrollTargetMobile!: ElementRef;
    sortField = 'name';
    sortOrder = 'asc';
    availableModels$: Observable<AvailableModelsResponse | undefined> = this.copService.availableModels$;
    loading$ = this.copService.loading$;
    quickFilter: string | undefined;
    vehiclesPerPage = 9;
    first = 0;
    contactSalesLink: LinkItem | null = null;
    contactButtonDescription: string = '';
    private languageChangeSubscription!: Subscription;
    private copBrandSubscription!: Subscription;

    constructor(private copService: CopService, private translateService: TranslateService) {}

    ngOnInit() {
        this.copService.getAvailableModels();
        this.copService.getCampaigns();

        this.copBrandSubscription = this.copService.copBrand$.pipe(
            filter((brand) => !!brand && !!brand.properties)
        ).subscribe((brand) => {
            const contactSalesLinkArray = brand.properties.contactSalesLink;
            if (contactSalesLinkArray && contactSalesLinkArray.length > 0) {
                this.contactSalesLink = contactSalesLinkArray[0];
            }
            this.contactButtonDescription = brand.properties.contactButtonDescription;
        });

        this.languageChangeSubscription = this.translateService.onLangChange.subscribe(() => {
            this.copService.getAvailableModels();
            this.copService.getCampaigns();
        });
    }

    ngOnDestroy() {
        if (this.languageChangeSubscription) {
            this.languageChangeSubscription.unsubscribe();
        }

        if (this.copBrandSubscription) {
            this.copBrandSubscription.unsubscribe();
        }
    }

    setQuickFilter(filterType: string): void {
        this.quickFilter = filterType;
    }

    onPageChange(event: any): void {
        this.scrollToTarget();
    }

    onContactUs(): void {
        if (this.contactSalesLink) {
            window.open(this.contactSalesLink?.url, this.contactSalesLink?.target);
        } else {
            console.error('Contact sales link is not available.');
        }
    }

    scrollToTarget(): void {
        if (window.innerWidth < 768) {
            if (this.scrollTargetMobile) {
                this.scrollTargetMobile.nativeElement.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            if (this.scrollTarget) {
                this.scrollTarget.nativeElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
}
