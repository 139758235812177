<div class="flex flex-column bg-khaki p-3">
    <div class="col-12">
        <h3 class="text-2xl font-bold pb-0 mb-0">{{ 'Financing Calculator' | translate }}</h3>
    </div>
    <div class="col-12 flex flex-wrap">
        <div class="col">
            <label class="block font-bold mb-2" [htmlFor]="'cash'">
                {{ 'Cash' | translate }}
            </label>
            <p-inputNumber
                [(ngModel)]="financingDetails.leaseIn"
                inputId="cash"
                mode="currency"
                currency="EUR"
                locale="fi-FI"
                size="small"
                [step]="100"
                [showButtons]="true"
                [min]="0"
                [max]="vehicle.Vehicle_Total_Retail_Price"
                (onInput)="cashChange()"
            />
        </div>
        <div class="col">
            <label class="block font-bold mb-2" [htmlFor]="'deviatingLastInstallment'">
                {{ 'Deviating Last Instalment' | translate }}
            </label>
            <p-inputNumber
                [(ngModel)]="financingDetails.leaseSplit"
                inputId="deviatingLastInstallment"
                mode="currency"
                currency="EUR"
                locale="fi-FI"
                size="small"
                [step]="100"
                [showButtons]="true"
                [min]="0"
                [max]="leaseSplit"
                (ngModelChange)="deviatingLastInstallmentChange($event)"
            />
        </div>
    </div>
    <div class="col-12 flex flex-wrap">
        <div class="col">
            <label class="block font-bold mb-2" [htmlFor]="'financingPeriod'">
                {{ 'Financing Period' | translate }}
            </label>
            <p-inputNumber
                [(ngModel)]="financingDetails.leaseMonths"
                inputId="financingPeriod"
                size="small"
                [step]="1"
                [showButtons]="true"
                [min]="0"
                [max]="financingPeriodMax"
                (onInput)="updateFinancingDetails()"
            />
        </div>
        <div class="col">
            <label class="block font-bold mb-2" [htmlFor]="'deviatingLastInstallmentPercentage'">
                {{ 'Deviating Last Instalment Percentage' | translate }}
            </label>
            <p-inputNumber
                [(ngModel)]="financingDetails.leaseSplitPercentage"
                inputId="deviatingLastInstallmentPercentage"
                size="small"
                suffix=" %"
                [step]="1"
                [showButtons]="true"
                [min]="0"
                [max]="leaseSplitPercentageMax"
                (ngModelChange)="deviatingLastInstallmentPercentageChange($event)"
            />
        </div>
    </div>
    <div class="col-12">
        <p-slider
            [(ngModel)]="financingDetails.leaseMonths"
            [step]="1"
            [min]="financingPeriodMin"
            [max]="financingPeriodMax"
            (onChange)="updateFinancingDetails()"
        />
        <div class="text-sm mt-2">*{{ 'Financing Calculator Slider Asterisk' | translate }}</div>
    </div>
    <div class="col-12 flex justify-content-between py-0">
        <h4 class="col text-xl font-medium mb-0">{{ 'Monthly Payment' | translate }}</h4>
        <div class="col pt-3 text-xl font-bold text-right">
            {{ formatCurrencyValue(pmt()) }}
        </div>
    </div>
    <div class="col-12 flex justify-content-between py-0">
        <div class="col align-content-center pl-0">
            <h3 class="col text-2xl font-bold">{{ 'Financing Example' | translate }}</h3>
        </div>
        <div class="col pt-3 text-2xl font-bold text-right">
            <i class="pi fa-solid fa-chevron-up"></i>
        </div>
    </div>
    <div class="col-12 flex justify-content-between py-0">
        <h4 class="col text-xl font-medium mb-0">{{ 'Deviating Last Instalment' | translate }}</h4>
        <div class="col pt-3 text-xl font-bold text-right">
            {{ formatCurrencyValue(financingDetails.leaseSplit) }}
        </div>
    </div>
    <div class="col-12 flex justify-content-between py-0">
        <h4 class="col text-xl font-medium mb-0">{{ 'Total Loan Amount' | translate }}</h4>
        <div class="col pt-3 text-xl font-bold text-right">
            {{ formatCurrencyValue(totalLoanAmount) }}
        </div>
    </div>
    <div class="col-12 flex justify-content-between py-0">
        <h4 class="col text-xl font-medium mb-0">{{ 'Number Of Instalments' | translate }}</h4>
        <div class="col pt-3 text-xl font-bold text-right">
            {{ financingDetails.leaseMonths }} {{ 'Instalments' | translate }}
        </div>
    </div>
    <div class="col-12 flex justify-content-between py-0">
        <h4 class="col text-xl font-medium mb-0">{{ 'Interest' | translate }}</h4>
        <div class="col pt-3 text-xl font-bold text-right">{{ formatNumberValue(financingDetails.leaseIntrest) }} %</div>
    </div>
    <div class="col-12 flex justify-content-between py-0">
        <h4 class="col text-xl font-medium mb-0">{{ 'Handling Fee' | translate }}</h4>
        <div class="col pt-3 text-xl font-bold text-right">
            {{ formatCurrencyValue(financingDetails.processingFee) }} / {{ 'perMonth' | translate }}
        </div>
    </div>
    <div class="col-12 flex justify-content-between py-0">
        <h4 class="col text-xl font-medium mb-0">{{ 'Setup Fee' | translate }}</h4>
        <div class="col pt-3 text-xl font-bold text-right">
            {{ formatCurrencyValue(financingDetails.establishmentFee) }}
        </div>
    </div>
    <div class="col-12 flex justify-content-between py-0">
        <h4 class="col text-xl font-medium mb-0">{{ 'Shipping Costs' | translate }}</h4>
        <div class="col pt-3 text-xl font-bold text-right">
            {{ formatCurrencyValue(deliveryFee) }}
        </div>
    </div>
    <div class="col-12">
        <p>{{ 'Financing Example Asterisk Text' | translate }}</p>
    </div>
</div>
