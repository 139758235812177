import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { ReplaySubject, BehaviorSubject, Subject } from 'rxjs';

import { webManiFest } from '../../../manifest';
import { ContentService } from './content.service';
import { BrandSettings } from '../../../../common/models/brand-settings';
import { LoginConfiguration } from '../../../../common/models/login-configuration';
import { BrandResponse } from '../../../../common/models/umbraco-responses/brand-response';
import { GoogleTagManagerService } from './google-tag-manager.service';

@Injectable({
    providedIn: 'root',
})
export class BrandService {
    primaryColor = '';
    brandSettings$ = new ReplaySubject<BrandSettings>(1);
    loginBackgroundPhotos$: Subject<string[]> = new ReplaySubject<string[]>(1);
    brandId$ = new BehaviorSubject('');

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private title: Title,
        private contentService: ContentService,
        private translateService: TranslateService,
        private googleTagManagerService: GoogleTagManagerService,
    ) {
        this.changeTheme(`theme-${this.brandNameString.toLowerCase()}.css`, 'light');
        this.getBrandId();
        this.getBrandingSettings();
        this.setTrackers();
    }

    get brandNameString(): string {
        if (window.location.hostname.indexOf('dacia') > -1) {
            return 'Dacia';
        } else if (window.location.hostname.indexOf('renault') > -1) {
            return 'Renault';
        } else {
            return 'Hyundai';
        }
    }

    getBrandLogo(type: 'white' | 'dark'): string {
        return `assets/images/logo/${this.brandNameString.toLowerCase()}-logo-${type}.svg`;
    }

    get brandColor(): string {
        if (window.location.hostname.indexOf('dacia') > -1) {
            return '#000000';
        } else if (window.location.hostname.indexOf('renault') > -1) {
            return '#000000';
        } else {
            return this.primaryColor;
        }
    }

    changeTheme(theme: string, colorScheme: string) {
        const themeLink = <HTMLLinkElement>this.document.getElementById('theme-css');
        const newHref = themeLink.getAttribute('href')!.replace('theme.css', theme);

        this.replaceThemeLink(newHref);
        this.replaceFaviconLink();
        this.setThemeColor();
        this.setWebManifest();

        this.title.setTitle(`${this.brandNameString} Cars`);
    }

    private replaceThemeLink(href: string) {
        const id = 'theme-css';
        const themeLink = <HTMLLinkElement>this.document.getElementById('theme-css');

        const cloneLinkElement = <HTMLLinkElement>themeLink.cloneNode(true);

        cloneLinkElement.setAttribute('href', href);
        cloneLinkElement.setAttribute('id', id + '-clone');

        themeLink.parentNode!.insertBefore(cloneLinkElement, themeLink.nextSibling);

        cloneLinkElement.addEventListener('load', () => {
            themeLink.remove();
            cloneLinkElement.setAttribute('id', id);
        });
    }

    private replaceFaviconLink() {
        const faviconLink = <HTMLLinkElement>this.document.getElementById('favicon');
        faviconLink.setAttribute('href', `assets/icons/${this.brandNameString.toLowerCase()}-favicon.ico`);
    }

    private setThemeColor() {
        const themeColorLink = <HTMLLinkElement>this.document.getElementById('theme-color');
        themeColorLink.setAttribute('content', `${this.brandColor}`);
    }

    private setWebManifest() {
        const stringManifest = JSON.stringify(webManiFest)
            .replace(new RegExp(/BASE_URL/g), window.location.origin)
            .replace(new RegExp(/BRAND_NAME/g), this.brandNameString.toLowerCase());

        const blob = new Blob([stringManifest], { type: 'application/json' });
        const manifestURL = URL.createObjectURL(blob);
        const manifestLink = this.document.querySelector('#web-manifest');

        if (!manifestLink) {
            return;
        }

        manifestLink.setAttribute('href', manifestURL);
    }

    private getBrandId() {
        const params = new HttpParams({
            fromObject: {
                filter: 'contentType:brand',
            },
        });

        this.contentService
            .getContentItemsFromQuery<{ total: number; items: BrandResponse[] }>(params)
            .pipe(take(1))
            .subscribe({
                next: (response) => {
                    const currentBrand = response.items.find(
                        (brandResponse) => brandResponse.name.toLowerCase() === this.brandNameString.toLowerCase(),
                    );

                    if (currentBrand) {
                        this.brandId$.next(currentBrand.id);
                    }
                },
            });
    }

    private getBrandingSettings() {
        const params = new HttpParams({
            fromObject: {
                filter: 'contentType:auth',
                fields: 'properties[$all]',
            },
        });

        this.contentService
            .getContentItemsFromQuery<{ total: number; items: LoginConfiguration[] }>(
                params,
                this.translateService.currentLang,
            )
            .pipe(take(1))
            .subscribe({
                next: (loginConfigurationResponse) => {
                    const root = loginConfigurationResponse.items[0];

                    if (!root) {
                        return;
                    }

                    const loginConfigurationItems = root.properties.authConfiguration.items;
                    const loginConfiguration = loginConfigurationItems.find(
                        (item) => item.content.properties.brand[0].name === this.brandNameString,
                    );

                    if (!loginConfiguration) {
                        return;
                    }

                    this.brandSettings$.next(loginConfiguration.content.properties);

                    if (!loginConfiguration.content.properties.brandBackgroundImages) {
                        this.loginBackgroundPhotos$.next([]);
                        return;
                    }

                    this.loginBackgroundPhotos$.next(
                        loginConfiguration.content.properties.brandBackgroundImages.map((image) => image.url),
                    );
                },
            });
    }

    private setTrackers(): void {
        if (this.brandNameString.toUpperCase() === 'HYUNDAI') {
            this.googleTagManagerService.setGoogleTagManagerScript();
        }
    }
}
