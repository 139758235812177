<ng-container *ngIf="campaigns$ | async as campaigns; else placeholder">
    <ng-container *ngIf="campaigns.length === 1; else multipleCampaigns">
        <button
            *ngIf="campaigns[0]"
            pButton
            icon="pi fa-solid fa-tag"
            severity="primary"
            label="{{ campaigns[0].title || '' }}"
            (click)="goToCampaign(campaigns[0].link)"
            [ngStyle]="{ 'background-color': campaignColor, 'margin-bottom': '5px' }"
        ></button>
    </ng-container>
    <ng-template #multipleCampaigns>
        <button
            pButton
            icon="pi fa-solid fa-tag"
            severity="primary"
            label="{{ 'Multiple campaigns' | translate }}"
            [ngStyle]="{ 'background-color': campaignColor, 'margin-bottom': '5px', 'opacity': '1.0' }"
            disabled
        ></button>
    </ng-template>
</ng-container>
<ng-template #placeholder>
    <div class="button-placeholder"></div>
</ng-template>