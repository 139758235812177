import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Output, EventEmitter } from '@angular/core';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { InputNumberModule } from 'primeng/inputnumber';
import { SliderModule } from 'primeng/slider';

import { HelperService } from '../../../framework/services/helper.service';
import { AvailableModel } from '../../../../../common/models/available-model';
import { FinancingDetails } from '../../../../../common/models/financing-details';
import { CopService } from '../../services/cop.service';
import { combineLatest, of, Subject, takeUntil } from 'rxjs';
import { CopBrandingResponse } from '../../../../../common/models/umbraco-responses/cop-branding-response';
import { Campaign } from 'src/common/models/campaign';
import { BrandService } from '../../services/brand.service';

@Component({
    selector: 'app-financing-calculator',
    standalone: true,
    imports: [FormsModule, NgIf, TranslateModule, InputNumberModule, SliderModule],
    templateUrl: './financing-calculator.component.html',
    styleUrl: './financing-calculator.component.scss',
})
export class FinancingCalculatorComponent implements OnInit, OnDestroy {
    @Input() vehicle!: AvailableModel;
    @Output() financingDetailsEvent = new EventEmitter<FinancingDetails>();

    financingDetails: FinancingDetails = {
        leaseIn: 0,
        leaseInPercentage: 10,
        leaseSplit: 0,
        leaseSplitPercentage: 20,
        priceWithDelivery: 0,
        financeRequired: 0,
        leaseMonths: 48,
        leaseIntrest: 0,
        establishmentFee: 0,
        processingFee: 0,
        monthlyPayment: 0,
        expenses: 0,
    };

    vehiclePriceExclAccessories = 0;
    accessoriesPrice = 0;
    carTax = 0;
    deliveryFee = 0;

    financingPeriodMax = 60;
    leaseSplitPercentageMax = 50;
    leaseSplit = 0;
    financingPeriodMin = 1;

    campaigns$ = this.copService.campaigns$;
    copBrand$ = this.brandService.copBrand$;

    private onDestroy = new Subject<void>();

    constructor(
        private helperService: HelperService,
        private copService: CopService,
        private brandService: BrandService
    ) {}

    ngOnInit() {
        if (!this.vehicle) {
            return;
        }

        combineLatest([this.copBrand$, this.campaigns$])
            .pipe(takeUntil(this.onDestroy))
            .subscribe({
                next: ([copBrand, campaigns]) => {
                    if (Object.keys(copBrand).length === 0) {
                        return;
                    }

                    let campaignCode: string | null = null;
                    if (this.vehicle.CampaignCode) {
                      campaignCode = this.vehicle.CampaignCode.split(',')[0].trim();
                    }
          
                    let applicableCampaign: Campaign | undefined;
                    if (campaignCode) {
                      applicableCampaign = campaigns.find(
                        (campaign) => campaign.code === campaignCode
                      );
                    }
          
                    this.initFinancingDetails(copBrand, applicableCampaign);
                    this.updateFinancingDetails();
                },
            });
    }

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    formatCurrencyValue(value: number | null, maximumFractionDigits = 2) {
        return this.helperService.formatCurrency(value, maximumFractionDigits);
    }

    formatNumberValue(value: number | null) {
        return this.helperService.formatNumber(value);
    }

    pmt(type: number = 0): number {
        const rate = this.financingDetails.leaseIntrest / 100 / 12;
        const nper = this.financingDetails.leaseMonths;
        const pv = this.totalLoanAmount;
        const fv = this.financingDetails.leaseSplit;

        if (rate === 0) {
            const pmt = (pv - fv) / nper;
            const monthlyPayment = parseFloat(pmt.toFixed(2));
            this.financingDetails.monthlyPayment = monthlyPayment;
            return monthlyPayment;
        }

        const pvif = Math.pow(1 + rate, nper);
        let pmt = (rate * (pv * pvif - fv)) / (pvif - 1);

        if (type === 1) {
            pmt /= 1 + rate;
        }

        const monthlyPayment = parseFloat(pmt.toFixed(2));
        this.financingDetails.monthlyPayment = monthlyPayment;

        return monthlyPayment;
    }

    get totalLoanAmount(): number {
        return (
            this.vehiclePriceExclAccessories +
            this.accessoriesPrice +
            this.financingDetails.establishmentFee -
            this.financingDetails.leaseIn
        );
    }

    get priceWithDelivery(): number {
        if (!this.vehicle) {
            return 0;
        }

        return (
            parseFloat(this.vehicle.Vehicle_Total_Price_Incl_Vat.toString()) +
            parseFloat(this.vehicle.Delivery_Cost.toString())
        );
    }

    cashChange(): void {
        this.financingDetails.leaseInPercentage = parseFloat(
            ((this.financingDetails.leaseIn / this.vehiclePriceExclAccessories) * 100).toFixed(2),
        );

        this.updateFinancingDetails();
    }

    deviatingLastInstallmentChange(value: number): void {
        if (value > this.financingDetails.leaseSplit) {
            this.financingDetails.leaseSplit = this.financingDetails.leaseSplit;
        }

        this.financingDetails.leaseSplitPercentage = parseFloat(
            ((this.financingDetails.leaseSplit / this.vehiclePriceExclAccessories) * 100).toFixed(0),
        );

        this.updateFinancingDetails();
    }

    deviatingLastInstallmentPercentageChange(value: number): void {
        if (value > this.leaseSplitPercentageMax) {
            this.financingDetails.leaseSplitPercentage = this.leaseSplitPercentageMax;
        }

        this.financingDetails.leaseSplit =
            (this.financingDetails.leaseSplitPercentage / 100) * this.vehiclePriceExclAccessories;

        this.updateFinancingDetails();
    }

    updateFinancingDetails(): void {
        this.financingDetails.financeRequired = this.totalLoanAmount;
        this.financingDetails.monthlyPayment = this.pmt();
        this.financingDetails.priceWithDelivery = this.priceWithDelivery;
        this.financingDetails.expenses = this.financingDetails.monthlyPayment + this.financingDetails.processingFee;

        this.financingDetailsEvent.emit(this.financingDetails);
    }

    private initFinancingDetails(copBrand: CopBrandingResponse, campaign?: Campaign): void {
        let properties;
        if (campaign && campaign.activeFinancialVariables) {
            properties = campaign;
        } else {
            properties = copBrand.properties;
        }

        this.financingDetails.processingFee = properties.monthlyProcessingFee;
        this.financingDetails.establishmentFee = properties.establishmentFee;
        this.financingDetails.leaseIntrest = properties.financialInterestPercentage;
        this.financingPeriodMax = properties.financingPeriodMax;
        this.financingDetails.leaseMonths = properties.financingPeriodMax;
        this.leaseSplitPercentageMax = properties.maxPercentagePeriodOfLastInstallment;
        this.financingDetails.leaseInPercentage = properties.downPaymentPercentage;
        this.financingDetails.leaseSplitPercentage = properties.maxPercentagePeriodOfLastInstallment;

        this.vehiclePriceExclAccessories = parseFloat(this.vehicle.Vehicle_Total_Retail_Price.toString());
        this.carTax = parseFloat(this.vehicle.Vehicle_Car_Tax.toString());
        this.deliveryFee = parseFloat(this.vehicle.Delivery_Cost ? this.vehicle.Delivery_Cost.toString() : '0');
        this.financingDetails.leaseIn =
            this.vehiclePriceExclAccessories * (this.financingDetails.leaseInPercentage / 100);
        this.financingDetails.leaseSplit =
            this.vehiclePriceExclAccessories * (this.financingDetails.leaseSplitPercentage / 100);
        this.leaseSplit = this.financingDetails.leaseSplit;
    }
}
