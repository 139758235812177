<div class="p-0 border-0 shadow-3" *ngIf="vehicle">
    <div class="p-3 p-card-header bg-khaki text-3xl font-bold">
        {{ vehicle.ModelSeries }}
    </div>
    <div class="p-3 p-card-body">
        <div class="flex flex-wrap align-items-center justify-content-between gap-2 campaign-button-style">
            <div class="flex align-items-center gap-2">
                <app-campaign-code-button [vehicle]="vehicle"></app-campaign-code-button>
            </div>
        </div>
        <div class="flex flex-column align-items-center gap-2 pb-3">
            <div style="min-height: 348px" class="flex align-items-center">
                <p-image src="{{ vehicle.PicUrl }}" alt="{{ vehicle.ModelName }}" height="auto" width="100%" />
            </div>
            <div class="text-2xl font-semibold text-primary w-11" style="min-height: 76px">{{ vehicle.ModelName }}</div>
            <div class="text-surface-500 w-11">{{ vehicle.LocationName }}</div>
            <div class="text-2xl font-semibold text-primary w-11">
                {{ formatCurrencyValue(vehicle.Vehicle_Total_Retail_Price, 0) }}
            </div>
        </div>
        <div class="flex align-items-center justify-content-between text-primary text-lg py-1">
            <div class="col">
                <i class="pi fa-solid fa-car-side mr-2"></i>
                {{ 'Body Type ' + vehicle.ModelBodyType | translate }}
            </div>
            <div class="col">
                <i class="pi fa-solid fa-tape mr-2"></i>
                {{ 'Transmission Type ' + vehicle.ModelTransmission | translate }}
            </div>
        </div>
        <div class="flex align-items-center justify-content-between text-primary text-lg">
            <div class="col">
                <i class="pi fa-solid fa-truck-monster mr-2"></i>
                {{ 'Drive Type ' + vehicle.ModelDriveType | translate }}
            </div>
            <div class="col">
                <i class="pi fa-solid fa-gas-pump mr-2"></i>
                {{ 'Driving Force ' + vehicle.ModelDrivingForce | translate }}
            </div>
        </div>
        <div class="flex align-items-start py-3">
            <button
                pButton
                icon="pi fa-solid fa-chevron-right"
                iconPos="right"
                size="large"
                label="{{ 'Button Select Car' | translate }}"
                [routerLink]="[vehicle.VehicleOid]"
            ></button>
        </div>
    </div>
</div>
