import { AppLayoutComponent } from './framework/layout/app.layout.component';
import { NotfoundComponent } from './framework/components/not-found/notfound.component';
import { LandingPageComponent } from './application/components/landing-page/landing-page.component';
import { VehicleModelComponent } from './application/components/vehicle-model/vehicle-model.component';
import { ContactFormComponent } from './application/components/contact-form/contact-form.component';

export const APP_ROUTES = [
    {
        path: '',
        component: AppLayoutComponent,
        children: [
            {
                path: '',
                canActivate: [],
                component: LandingPageComponent,
            },
            {
                path: ':vehicleOid',
                canActivate: [],
                component: VehicleModelComponent,
            },
            {
                path: ':vehicleOid/contact',
                canActivate: [],
                component: ContactFormComponent,
            },
        ],
    },
    { path: 'notfound', component: NotfoundComponent },
    { path: '**', redirectTo: '/notfound' },
];
