<div class="grid justify-content-center">
    <div class="col-12">
        <app-hero-campaign></app-hero-campaign>
    </div>
    <div class="col-12 mb-4" style="padding: 0 46px 0 46px">
        <h1>{{ 'Landing Page Title' | translate }}</h1>
        <p>{{ 'Landing Page Sub Title' | translate }}</p>
    </div>
    <div #scrollTarget class="col-12 grid justify-content-center gap-2 mb-4">
        <p-button
            (click)="setQuickFilter('ev')"
            label="{{ 'Button Filter Only EVs' | translate }}"
            icon="pi fa-solid fa-plug-circle-bolt"
            size="large"
        ></p-button>
        <p-button
            (click)="setQuickFilter('awd')"
            label="{{ 'Button Filter Only AWD' | translate }}"
            icon="pi fa-solid fa-truck-monster"
            size="large"
        ></p-button>
        <p-button
            (click)="setQuickFilter('phev')"
            label="{{ 'Button Filter Only Phevs' | translate }}"
            icon="pi fa-solid fa-plug"
            size="large"
        ></p-button>
        <p-button
            (click)="setQuickFilter('automatic')"
            label="{{ 'Button Filter Only Automatic' | translate }}"
            icon="pi fa-solid fa-gears"
            size="large"
        ></p-button>
        <p-button
            (click)="setQuickFilter('campaignCode')"
            label="{{ 'Button Filter Only Campaign' | translate }}"
            icon="pi fa-solid fa-bullhorn"
            size="large"
        ></p-button>
    </div>
    <div class="col-12 mb-4 text-center" *ngIf="contactButtonDescription && contactSalesLink">
        <p>{{ contactButtonDescription }}</p>
        <p-button
            label="{{ 'Contact us' | translate }}"
            (click)="onContactUs()"
            size="large"
        ></p-button>
    </div>
    
    <div #scrollTargetMobile class="col-12 grid justify-content-evenly">
        <div class="col-12 md:col-5 lg:col-4 xl:col-3">
            <app-filter [quickFilter]="quickFilter"></app-filter>
        </div>
        <div class="col-12 md:col-7 lg:col-8 xl:col-9 grid gap-1" *ngIf="loading$ | async; else dataView">
            <p-dataView #skeletonGrid [value]="[1, 2, 3, 4, 5, 6, 7, 8, 9]" [layout]="'grid'">
                <ng-template let-product pTemplate="grid" let-products>
                    <div class="grid grid-nogutter">
                        <div
                            class="col-12 sm:col-6 lg:col-12 xl:col-4 p-2"
                            *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9]"
                        >
                            <div class="p-0 border-0 shadow-3">
                                <div class="p-3 p-card-header bg-khaki text-3xl font-bold">
                                    <p-skeleton width="10rem" height="2rem" />
                                </div>
                                <div class="p-3 p-card-body">
                                    <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                                        <div class="flex align-items-center gap-2">
                                            <p-skeleton width="10rem" height="2rem" />
                                        </div>
                                    </div>
                                    <div class="flex flex-column align-items-center gap-2 pb-3">
                                        <div style="min-height: 280px" class="flex align-items-center">
                                            <p-skeleton size="10rem" />
                                        </div>
                                        <div class="text-2xl font-semibold text-primary w-11" style="min-height: 52px">
                                            <p-skeleton height="4rem" />
                                        </div>
                                        <div class="text-surface-500 w-11">
                                            <p-skeleton height="1rem" />
                                        </div>
                                        <div class="text-2xl font-semibold text-primary w-11">
                                            <p-skeleton height="2rem" />
                                        </div>
                                    </div>
                                    <div
                                        class="flex align-items-center justify-content-between text-primary text-lg py-1"
                                    >
                                        <div class="col">
                                            <p-skeleton styleClass="4rem" height="2rem" />
                                        </div>
                                        <div class="col">
                                            <p-skeleton styleClass="4rem" height="2rem" />
                                        </div>
                                    </div>
                                    <div class="flex align-items-center justify-content-between text-primary text-lg">
                                        <div class="col">
                                            <p-skeleton styleClass="4rem" height="2rem" />
                                        </div>
                                        <div class="col">
                                            <p-skeleton styleClass="4rem" height="2rem" />
                                        </div>
                                    </div>
                                    <div class="flex align-items-start py-3">
                                        <p-skeleton width="6rem" height="3rem" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-dataView>
        </div>
        <ng-template #dataView>
            <div class="col-12 md:col-7 lg:col-8 xl:col-9 grid gap-1">
                <ng-container *ngIf="availableModels$ | async as availableModels">
                    <p-dataView
                        (onPage)="onPageChange($event)"
                        #dv
                        [value]="availableModels.results"
                        layout="grid"
                        [rows]="vehiclesPerPage"
                        [totalRecords]="availableModels.pagination.total"
                        [sortField]="sortField"
                        [sortOrder]="sortOrder"
                        [ngStyle]="{ width: '100%' }"
                        [paginator]="true"
                        [paginatorPosition]="'both'"
                        emptyMessage="{{ 'No Results' | translate }}"
                    >
                        <ng-template pTemplate="grid" let-vehicles>
                            <div class="grid grid-nogutter">
                                <div class="col-12 xl:col-4 p-2" *ngFor="let vehicle of vehicles">
                                    <app-car-tile [vehicle]="vehicle"></app-car-tile>
                                </div>
                            </div>
                        </ng-template>
                    </p-dataView>
                </ng-container>
            </div>
        </ng-template>
    </div>
</div>
