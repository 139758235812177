import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { v4 as uuid } from 'uuid';

import { environment } from '../../../environment/environment';

@Injectable({
    providedIn: 'root',
})
export class ContentService {
    constructor(private http: HttpClient) {}

    getContentItemsFromQuery<T>(params: HttpParams, language?: string) {
        return this.http.get<T>(`${this.url}umbraco/delivery/api/v2/content`, {
            headers: this.getHeaders(language),
            params,
        });
    }

    getHeaders(language?: string) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            accept: 'application/json',
            'Request-ID': uuid(),
        });

        if (language) {
            headers = headers.append('Accept-Language', language);
        }

        return headers;
    }

    get url(): URL {
        return typeof window !== 'undefined' ? new URL(environment.apiUrl) : new URL('http://127.0.0.1:8889'); // tests
    }
}
