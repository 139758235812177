import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ButtonModule } from 'primeng/button';
import { CarouselModule } from 'primeng/carousel';

import { Campaign } from '../../../../../common/models/campaign';
import { CopService } from '../../services/cop.service';

@Component({
    selector: 'app-hero-campaign',
    standalone: true,
    imports: [ButtonModule, TranslateModule, CarouselModule, RouterLink],
    templateUrl: './hero-campaign.component.html',
    styleUrl: './hero-campaign.component.scss',
})
export class HeroCampaignComponent implements OnInit {
    isDesktop = true;
    campaigns$: Observable<Campaign[]> = this.copService.campaigns$;

    constructor(private copService: CopService) {}

    ngOnInit() {
        this.checkScreenSize();
    }

    checkScreenSize(): void {
        this.isDesktop = window.innerWidth > 576;
    }

    goToCampaign(link: string) {
        window.open(link, '_blank');
    }
}
