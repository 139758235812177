import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ButtonModule } from 'primeng/button';
import { CarouselModule } from 'primeng/carousel';

import { Campaign } from '../../../../../common/models/campaign';
import { CopService } from '../../services/cop.service';
import { CopBrandingResponse } from 'src/common/models/umbraco-responses/cop-branding-response';
import { BrandService } from '../../services/brand.service';

@Component({
    selector: 'app-hero-campaign',
    standalone: true,
    imports: [ButtonModule, TranslateModule, CarouselModule, RouterLink],
    templateUrl: './hero-campaign.component.html',
    styleUrl: './hero-campaign.component.scss',
})
export class HeroCampaignComponent implements OnInit {
    isDesktop = true;
    campaigns$: Observable<Campaign[]> = this.copService.campaigns$;
    campaignColor: string | undefined;
    campaignTextColor: string | undefined;

    constructor(private copService: CopService, private brandService: BrandService) {}

    ngOnInit() {
        this.checkScreenSize();

        this.brandService.copBrand$.subscribe({
            next: (copBrand: CopBrandingResponse) => {
                if (Object.keys(copBrand).length === 0) {
                    return;
                }
                this.campaignColor = copBrand.properties.campaignColor;
                this.campaignTextColor = copBrand.properties.campaignTextColor;
            }
        });
    }

    checkScreenSize(): void {
        this.isDesktop = window.innerWidth > 576;
    }

    goToCampaign(link: string) {
        window.open(link, '_blank');
    }
}
