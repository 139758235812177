import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { debounceTime, map, Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { AccordionModule } from 'primeng/accordion';
import { SelectButtonModule } from 'primeng/selectbutton';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ButtonModule } from 'primeng/button';

import { CopService } from '../../services/cop.service';
import { Campaign } from 'src/common/models/campaign';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-filter',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        AccordionModule,
        DropdownModule,
        SelectButtonModule,
        CheckboxModule,
        ReactiveFormsModule,
        ProgressSpinnerModule,
        ButtonModule,
    ],
    templateUrl: './filter.component.html',
    styleUrl: './filter.component.scss',
})
export class FilterComponent implements OnInit, OnChanges, OnDestroy {
    @Input() quickFilter: string | undefined;

    sortOrderOptions: any[] = [];
    private langChangeSub!: Subscription;
    bodyTypeOptions = this.buildBodyTypeOptions();
    driveTypeOptions = this.buildDriveTypeOptions();
    drivingForceTypeOptions = this.buildDrivingForceTypeOptions();
    transmissionTypeOptions = this.buildTransmissionTypeOptions();

    campaigns$: Observable<Campaign[]> = this.copService.campaigns$;
    campaignCodeOptions$ = this.campaigns$.pipe(
        map((campaigns: Campaign[]) => campaigns.map((campaign) => ({ label: campaign.title, value: campaign.code }))),
    );

    modelOptions: { label: string; value: string }[] = [];
    filters: FormGroup = this.formBuilder.group(this.buildDefaultControls());
    originalCampaignCode: string | null = null;
    loadingModels$ = this.copService.loadingModels$;

    campaignCodeOptions: { label: string; value: string }[] = [];

    private onDestroy = new Subject<void>();

    constructor(
        private formBuilder: FormBuilder,
        private copService: CopService,
        private translateService: TranslateService,
        private route: ActivatedRoute,
        private router: Router,
    ) {}

    ngOnInit() {
        this.initializeFilters();
        this.subscribeToQueryParams();
        this.subscribeToFilterChanges();
        this.subscribeToModels();

        this.buildAndSetSortOrderOptions();
        this.langChangeSub = this.translateService.onLangChange.subscribe(() => {
          this.buildAndSetSortOrderOptions();
        });

        this.campaignCodeOptions$.subscribe((campaignOptions) => {
            this.campaignCodeOptions = campaignOptions;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['quickFilter']?.currentValue) {
            this.handleQuickFilterChange(changes['quickFilter'].currentValue);
        }
    }

    ngOnDestroy() {

        if (this.langChangeSub) {
            this.langChangeSub.unsubscribe();
          }

        this.onDestroy.next();
        this.onDestroy.complete();
    }

    clearFilters(): void {
        this.copService.filterString = '';
        this.clearCampaignCode();
        this.resetFilters();
        this.copService.getAvailableModels();
    }

    clearCampaignCode() {
        this.router.navigate([], {
            queryParams: { campaignCode: null },
            queryParamsHandling: 'merge',
        });
    }

    private buildAndSetSortOrderOptions(): void {
        this.sortOrderOptions = [
          { label: this.translateService.instant('Ascending'), value: 'ASC' },
          { label: this.translateService.instant('Descending'), value: 'DESC' },
        ];
      }

    private buildBodyTypeOptions() {
        return [
            { label: 'Body Type SED', value: 'SED' },
            { label: 'Body Type Hatchback', value: 'HAT' },
            { label: 'Body Type SUV', value: 'SUV' },
            { label: 'Body Type LCV', value: 'LCV' },
            { label: 'Body Type Pick-Up', value: 'PUP' },
            { label: 'Body Type Wagon', value: 'WAG' },
        ];
    }

    private buildDriveTypeOptions() {
        return [
            { label: 'Drive Type FWD', value: 'FWD' },
            { label: 'Drive Type RWD', value: 'RWD' },
            { label: 'Drive Type AWD', value: 'AWD' },
        ];
    }

    private buildDrivingForceTypeOptions() {
        return [
            { label: 'Driving Force EL', value: 'EL' },
            { label: 'Driving Force BE', value: 'BE' },
            { label: 'Driving Force DI', value: 'DI' },
        ];
    }

    private buildTransmissionTypeOptions() {
        return [
            { label: 'Transmission Type Manual', value: ['M', '2'] },
            { label: 'Transmission Type Automatic', value: ['A', '1'] },
        ];
    }

    private buildDefaultControls() {
        return {
            SortOrder: 'ASC',
            ModelBodyType: null,
            ModelDriveType: null,
            ModelDrivingForce: null,
            DrivingForceOption: null,
            ModelTransmission: null,
            ModelSeries: null,
            CampaignCode: null,
        };
    }

    private initializeFilters(): void {
        this.resetFilters();
        this.copService.getModels();
    }

    private subscribeToQueryParams(): void {
        this.route.queryParams.subscribe((params) => {
            const campaignCodes = params['campaignCode'];
            if (campaignCodes) {
                this.filters.get('CampaignCode')?.setValue(campaignCodes.split(','));
                this.triggerApiCall();
                this.originalCampaignCode = this.filters.get('CampaignCode')?.value;
            }
        });
    }

    private subscribeToFilterChanges(): void {
        this.filters.valueChanges.pipe(debounceTime(800), takeUntil(this.onDestroy)).subscribe(() => {
            this.updateUrlWithFilters();
            this.triggerApiCall();
        });
    }

    private subscribeToModels(): void {
        this.copService.models$.pipe(takeUntil(this.onDestroy)).subscribe({
            next: (models) => (this.modelOptions = models),
        });
    }

    private resetFilters(): void {
        const filterString = this.copService.filterString;
        if (!filterString) {
            this.filters.patchValue(this.buildDefaultControls(), { emitEvent: false });
        } else {
            this.applyExistingFilters(filterString);
        }
        this.copService.getModels();
    }

    private applyExistingFilters(filterString: string): void {
        const filters = filterString.split('&');
        filters.forEach((filter) => {
            const [key, value] = filter.split('=');
            this.filters.get(key)?.setValue(value.split(','));
        });
    }

    private handleQuickFilterChange(filterType: string): void {
        this.copService.filterString = '';
        this.clearCampaignCode();
        this.resetFilters();
        const filterMapping: { [key: string]: () => void } = {
            ev: () => this.filters.get('ModelDrivingForce')?.setValue(['EL']),
            awd: () => this.filters.get('ModelDriveType')?.setValue(['AWD']),
            phev: () => this.filters.get('DrivingForceOption')?.setValue('PHEV'),
            automatic: () => this.filters.get('ModelTransmission')?.setValue(['A', '1']),
            campaignCode: () => this.applyCampaignCodeFilter(),
        };

        filterMapping[filterType]?.();
    }

    private applyCampaignCodeFilter(): void {
        const allCampaignCodes = this.campaignCodeOptions.map((option) => option.value);
        this.filters.get('CampaignCode')?.setValue(allCampaignCodes);
        this.updateUrlWithFilters();
    }

    private updateUrlWithFilters(): void {
        const campaignCodes = this.filters.get('CampaignCode')?.value;
        const params = campaignCodes?.length ? { campaignCode: campaignCodes.join(',') } : {};
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
            queryParamsHandling: 'merge',
        });
    }

    private get filterString(): string {
        let filterString = '';

        Object.keys(this.filters.value).forEach((key, index) => {
            if (this.filters.value[key] && this.filters.value[key].length) {
                if (index === 0) {
                    filterString += `${key}=${this.filters.value[key]}`;
                } else {
                    filterString += `&${key}=${this.filters.value[key]}`;
                }
            }
        });

        return filterString;
    }

    private triggerApiCall(): void {
        this.copService.filterString = this.filterString;
        this.copService.getAvailableModels();
        this.modelOptions = [];
        this.copService.getModels();
    }
}
