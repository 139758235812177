import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { BehaviorSubject, take, Subject } from 'rxjs';
import { webManiFest } from '../../../manifest';
import { BrandSettings } from '../../../../common/models/brand-settings';
import { GoogleTagManagerService } from './google-tag-manager.service';
import { CopBrandingResponse } from 'src/common/models/umbraco-responses/cop-branding-response';
import { environment } from 'src/frontend/environment/environment';
import { Brand } from 'src/common/models/enumeration/brand';
import { HttpParams } from '@angular/common/http';
import { ContentService } from './content.service';
import { TranslateService } from '@ngx-translate/core';
import { BrandResponse } from 'src/common/models/umbraco-responses/brand-response';
import { SeoProperties } from 'src/common/models/umbraco-responses/seo-properties';
import { BrandProperties } from 'src/common/models/umbraco-responses/brand-properties';
import { SplunkService } from './splunk.service';

@Injectable({
    providedIn: 'root',
})
export class BrandService {
    private static readonly DOMAIN_COUNTRY_MAP: { [key: string]: string } = {
        com: 'en-us',
        fi: 'fi-fi',
        lv: 'lv-lv',
        ee: 'et-ee',
        lt: 'lt-lt',
    };

    private _countryIsoCode!: string;
    private _currentBrandId?: string;
    private _languageKey!: string;
    private currentBrandProperties: BrandProperties | null = null;
    primaryColor = '';
    private brandSettingsSubject = new Subject<BrandSettings>();
    private loginBackgroundPhotosSubject = new Subject<string[]>();
    private copBrandSubject = new BehaviorSubject<CopBrandingResponse>({} as CopBrandingResponse);
    private brandIdSubject = new BehaviorSubject<string>('');
    brandSettingsLoaded$ = new BehaviorSubject<boolean>(false);

    public brandSettings$ = this.brandSettingsSubject.asObservable();
    public loginBackgroundPhotos$ = this.loginBackgroundPhotosSubject.asObservable();
    public copBrand$ = this.copBrandSubject.asObservable();
    public brandId$ = this.brandIdSubject.asObservable();

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private title: Title,
        private meta: Meta,
        private googleTagManagerService: GoogleTagManagerService,
        private splunkService: SplunkService,
        private contentService: ContentService,
        private translateService: TranslateService,
    ) {
        this.initializeLanguageSettings();
        this.loadAndApplyBrandSettings();
        this.setCurrentBrandId();
        this.setTrackers();
    }

    get brandNameString(): string {
        const hostname = window.location.hostname.toLowerCase();
        if (hostname.includes('dacia')) return Brand.Dacia;
        if (hostname.includes('renault')) return Brand.Renault;
        if (hostname.includes('nissan')) return Brand.Nissan;
        return Brand.Hyundai;
    }

    /**
     * Returns the ISO country code based on the domain.
     * For example, a domain with key "fi" returns "fi-fi".
     */
    get countryIsoCode(): string {
        return this._countryIsoCode;
    }

    /**
     * Returns the language key based on the domain.
     * This key is one of the keys from DOMAIN_COUNTRY_MAP (fi, lv, ee, lt)
     * and explicitly ignores the "com" key.
     */
    get languageKey(): string {
        return this._languageKey;
    }

    private initializeLanguageSettings(): void {
        const defaultSuffix = environment.production ? 'fi' : 'com';
        this._countryIsoCode =
            BrandService.DOMAIN_COUNTRY_MAP[this.getDomainKey(defaultSuffix)] ||
            BrandService.DOMAIN_COUNTRY_MAP[defaultSuffix];
        this._languageKey = this.getDomainKey('fi', ['com']);
        this.translateService.currentLang = this.countryIsoCode;
    }

    setCurrentBrandId(): string | undefined {
        if (this._currentBrandId) {
            return this._currentBrandId;
        }
        const brandName = this.brandNameString;
        const currentBrandName = this.getCurrentBrandConfig();
        if (!currentBrandName) {
            console.warn(`No brand node ID found for brand: ${brandName}`);
            this.resetBrandData();
            return undefined;
        }

        this._currentBrandId = currentBrandName.id;
        this.brandIdSubject.next(currentBrandName.id);
        this.fetchBrandingData(currentBrandName.id, brandName);

        return this._currentBrandId;
    }

    get currentBrandId(): string | undefined {
        return this._currentBrandId;
    }

    private resetBrandData(): void {
        this.copBrandSubject.next({} as CopBrandingResponse);
        this.brandIdSubject.next('');
    }

    private fetchBrandingData(brandId: string, brandName: string): void {
        const params = new HttpParams({
            fromObject: {
                fetch: `descendants:${brandId}`,
                fields: 'properties[$all]',
                skip: '0',
                take: '10',
            },
        });

        this.contentService
            .getContentItemsFromQuery<{ total: number; items: CopBrandingResponse[] }>(
                params,
                this.countryIsoCode || this.translateService.defaultLang,
            )
            .pipe(take(1))
            .subscribe({
                next: (response) => this.handleBrandingResponse(response, brandName),
                error: (error) => this.handleBrandingError(error),
            });
    }

    private handleBrandingResponse(response: { total: number; items: CopBrandingResponse[] }, brandName: string): void {
        this.updateCopBrand(response, brandName, (items) => {
            const matchedItem = items.find(
                (item) =>
                    item.contentType === 'copCountryPerBrand' && item.cultures && item.cultures[this.countryIsoCode],
            );

            if (matchedItem?.properties?.seo) {
                this.setSeoTags(matchedItem.properties.seo);
            }

            return matchedItem;
        });
    }

    private updateCopBrand(
        response: { total: number; items: CopBrandingResponse[] },
        brandName: string,
        finderFn: (items: CopBrandingResponse[]) => CopBrandingResponse | undefined,
    ): void {
        if (!response.items || response.items.length === 0) {
            this.emitEmptyCopBrand(brandName);
            return;
        }

        const matchingBrand = finderFn(response.items);

        if (!matchingBrand) {
            this.emitEmptyCopBrand(brandName);
            return;
        }

        this.copBrandSubject.next(matchingBrand);
    }

    private emitEmptyCopBrand(context: string): void {
        console.warn(`No copBrand found for brand/context: ${context}`);
        this.copBrandSubject.next({} as CopBrandingResponse);
    }

    private handleBrandingError(error: any): void {
        console.error('Error fetching brand data:', error);
        this.copBrandSubject.next({} as CopBrandingResponse);
    }

    getBrandLogo(type: 'white' | 'dark'): string {
        if (!this.currentBrandProperties) {
            return '';
        }
        const mediaItems =
            type === 'white' ? this.currentBrandProperties.brandLogoWhite : this.currentBrandProperties.brandLogoDark;

        if (!mediaItems || mediaItems.length === 0) {
            return '';
        }

        return `${environment.apiUrl}${mediaItems[0].url}`;
    }

    get brandColor(): string {
        if (window.location.hostname.indexOf('dacia') > -1) {
            return '#000000';
        } else if (window.location.hostname.indexOf('renault') > -1) {
            return '#000000';
        } else {
            return this.primaryColor;
        }
    }

    changeTheme(theme: string, colorScheme: string) {
        const themeLink = <HTMLLinkElement>this.document.getElementById('theme-css');
        const newHref = themeLink.getAttribute('href')!.replace('theme.css', theme);

        this.replaceThemeLink(newHref);
        this.replaceFaviconLink();
        this.setThemeColor();
        this.setWebManifest();
    }

    private replaceThemeLink(href: string) {
        const id = 'theme-css';
        const themeLink = <HTMLLinkElement>this.document.getElementById('theme-css');

        const cloneLinkElement = <HTMLLinkElement>themeLink.cloneNode(true);

        cloneLinkElement.setAttribute('href', href);
        cloneLinkElement.setAttribute('id', id + '-clone');

        themeLink.parentNode!.insertBefore(cloneLinkElement, themeLink.nextSibling);

        cloneLinkElement.addEventListener('load', () => {
            themeLink.remove();
            cloneLinkElement.setAttribute('id', id);
        });
    }

    private replaceFaviconLink() {
        const faviconLink = <HTMLLinkElement>this.document.getElementById('favicon');
        if (!faviconLink) {
            return;
        }

        const logoUrl = this.getBrandLogo('dark');
        faviconLink.setAttribute('href', logoUrl);
    }

    private setThemeColor() {
        const themeColorLink = <HTMLLinkElement>this.document.getElementById('theme-color');
        themeColorLink.setAttribute('content', `${this.brandColor}`);
    }

    private setWebManifest() {
        const stringManifest = JSON.stringify(webManiFest)
            .replace(new RegExp(/BASE_URL/g), window.location.origin)
            .replace(new RegExp(/BRAND_NAME/g), this.brandNameString.toLowerCase());

        const blob = new Blob([stringManifest], { type: 'application/json' });
        const manifestURL = URL.createObjectURL(blob);
        const manifestLink = this.document.querySelector('#web-manifest');

        if (!manifestLink) {
            return;
        }

        manifestLink.setAttribute('href', manifestURL);
    }

    private setTrackers(): void {
        const configBrand = this.getCurrentBrandConfig();
        if (configBrand && configBrand.gtmId) {
            this.googleTagManagerService.setGoogleTagManagerScript(configBrand.gtmId);
        }
        if (configBrand?.name === 'Hyundai') {
            this.splunkService.setSplunkScript();
        }
    }

    private getCurrentBrandConfig(): any {
        const brandName = this.brandNameString;
        return environment.brands.find((brand) => brand.name.toLowerCase() === brandName.toLowerCase());
    }

    loadAndApplyBrandSettings(): Promise<void> {
        return new Promise((resolve) => {
            const params = new HttpParams({
                fromObject: {
                    filter: 'contentType:copBrand',
                    fields: 'properties[$all]',
                },
            });

            this.contentService
                .getContentItemsFromQuery<{ total: number; items: BrandResponse[] }>(
                    params,
                    this.countryIsoCode || this.translateService.defaultLang,
                )
                .pipe(take(1))
                .subscribe({
                    next: (brandResponse) => {
                        if (!brandResponse || !brandResponse.items || !brandResponse.items.length) {
                            return;
                        }

                        const matchedBrand = brandResponse.items.find(
                            (item) => item.name.toLowerCase() === this.brandNameString.toLowerCase(),
                        );

                        if (!matchedBrand) {
                            return;
                        }
                        this.currentBrandProperties = matchedBrand.properties;
                        this.handleCssBrandProperties(matchedBrand.properties);
                        this.changeTheme(`theme-${this.brandNameString.toLowerCase()}.css`, 'light');
                        this.brandSettingsLoaded$.next(true);
                        resolve();
                    },
                    error: (err) => {
                        console.error('Error fetching brand colors:', err);
                        resolve();
                    },
                });
        });
    }

    private handleCssBrandProperties({ primaryColor, secondaryColor, lighterColor }: BrandProperties): void {
        this.applyDynamicCssVariables({
            primaryColor: primaryColor ?? '#002c5f',
            secondaryColor: secondaryColor ?? '#2160aa',
            lighterColor: lighterColor ?? '#e9f1f7',
        });
    }

    private applyDynamicCssVariables(
        props: Pick<BrandProperties, 'primaryColor' | 'secondaryColor' | 'lighterColor'>,
    ): void {
        const rootStyle = this.document.documentElement.style;

        if (props.primaryColor) {
            rootStyle.setProperty('--brand-primary', props.primaryColor);
        }
        if (props.secondaryColor) {
            rootStyle.setProperty('--brand-secondary', props.secondaryColor);
        }
        if (props.lighterColor) {
            rootStyle.setProperty('--brand-lighter', props.lighterColor);
        }
    }

    private setSeoTags(seoProperties: SeoProperties) {
        if (seoProperties.title) {
            this.title.setTitle(seoProperties.title);
        }
        if (seoProperties.description) {
            this.meta.updateTag({ name: 'description', content: seoProperties.description });
        }
        if (seoProperties.noIndex) {
            this.meta.updateTag({ name: 'robots', content: 'noindex, nofollow' });
        }
    }

    /**
     * Helper method to extract a domain key from the hostname.
     *
     * @param defaultKey The default key to use if no valid key is found.
     * @param excludeKeys Optional list of keys to ignore.
     * @returns The key from the hostname (if available and valid) or the default.
     */
    private getDomainKey(defaultKey: string, excludeKeys: string[] = []): string {
        const hostname = window.location.hostname.toLowerCase();
        const validKeys = Object.keys(BrandService.DOMAIN_COUNTRY_MAP);
        const isLocal = hostname.includes('.localnet');

        if (isLocal) {
            // Expected pattern: "nissan-ee-cop.localnet" → segments: ["nissan", "ee", "cop"]
            const segments = hostname.split('.')[0].split('-');
            if (segments.length > 1) {
                const candidate = segments[1];
                if (validKeys.includes(candidate) && !excludeKeys.includes(candidate)) {
                    return candidate;
                }
            }
        } else {
            const parts = hostname.split('.');
            const tld = parts[parts.length - 1];
            // If TLD is not "com" and is valid, use it.
            if (tld !== 'com' && validKeys.includes(tld) && !excludeKeys.includes(tld)) {
                return tld;
            } else {
                // For domains like "test-hyundai-fi.cop.bassadoneservices.com" or "staging-store.nissan.ee"
                // if the TLD is "com" (or not valid), inspect the first segment.
                const firstPart = parts[0];
                const segments = firstPart.split('-');
                // Iterate from the last segment backwards.
                for (let i = segments.length - 1; i >= 0; i--) {
                    const candidate = segments[i];
                    if (validKeys.includes(candidate) && !excludeKeys.includes(candidate)) {
                        return candidate;
                    }
                }
            }
        }
        return defaultKey;
    }
}
