import { isDevMode, importProvidersFrom, APP_INITIALIZER, Injector, enableProdMode } from '@angular/core';
import { withInterceptorsFromDi, provideHttpClient, HttpClient } from '@angular/common/http';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
    PreloadAllModules,
    provideRouter,
    withComponentInputBinding,
    withInMemoryScrolling,
    withPreloading,
} from '@angular/router';
import localeFinnish from '@angular/common/locales/fi';
import { LOCATION_INITIALIZED, registerLocaleData } from '@angular/common';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { switchMap } from 'rxjs';
import { take } from 'rxjs/operators';
import { PrimeNGConfig } from 'primeng/api';

import { APP_ROUTES } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { UmbracoTranslateLoader } from './app/application/umbraco-translate-loader';
import { ContentService } from './app/application/services/content.service';
import { environment } from './environment/environment';
import { BrandService } from './app/application/services/brand.service';

const domainLanguageMap: { [key: string]: string } = {
    com: 'en-US',
    fi: 'fi-FI',
    lv: 'lv-LV',
    ee: 'et-EE',
    lt: 'lt-LT',
};

export function initializeApp(translateService: TranslateService, injector: Injector, config: PrimeNGConfig) {
    return () =>
        new Promise<any>((resolve: any) => {
            const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
            locationInitialized.then(() => {
                const hostname = window.location.hostname;
                let langToSet: string | undefined;
                for (const [key, lang] of Object.entries(domainLanguageMap)) {
                    // Skip generic domain keys like 'com'
                    if (key === 'com') {
                        continue;
                    }
                    // Look for a hyphenated version to avoid false matches.
                    if (hostname.includes(`-${key}`)) {
                        langToSet = lang;
                        break;
                    }
                }
                
                // Fallback to using the domain suffix if no language key was found
                if (!langToSet) {
                    const domainSuffix = hostname.split('.').pop();
                    const defaultSuffix = environment.production ? 'fi' : 'com';
                    langToSet =
                        domainSuffix && Object.keys(domainLanguageMap).includes(domainSuffix)
                            ? domainLanguageMap[domainSuffix]
                            : domainLanguageMap[defaultSuffix];
                }

                translateService
                    .use(langToSet)
                    .pipe(switchMap(() => translateService.get('primeng')))
                    .pipe(take(1))
                    .subscribe({
                        next: (res) => {
                            config.setTranslation(res);
                            registerLocaleData(localeFinnish);
                            console.info(`Successfully initialized '${langToSet}' language`);
                        },
                        error: (err) => {
                            console.error(`Problem with '${langToSet}' language initialization`);
                        },
                        complete: () => {
                            resolve(null);
                        },
                    });
            });
        });
}

export function initializeBrand(brandService: BrandService): () => Promise<void> {
    return () => brandService.loadAndApplyBrandSettings();
}

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            multi: true,
            deps: [TranslateService, Injector, PrimeNGConfig],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeBrand,
            multi: true,
            deps: [BrandService],
        },
        importProvidersFrom(
            BrowserModule,
            ServiceWorkerModule.register('ngsw-worker.js', {
                enabled: !isDevMode(),
                registrationStrategy: 'registerWhenStable:30000',
            }),
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useClass: UmbracoTranslateLoader,
                    deps: [HttpClient, ContentService],
                },
                defaultLanguage: 'fi-FI',
            }),
        ),
        provideRouter(
            APP_ROUTES,
            withComponentInputBinding(),
            withInMemoryScrolling({
                scrollPositionRestoration: 'enabled',
                anchorScrolling: 'enabled',
            }),
            withPreloading(PreloadAllModules),
        ),
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
    ],
}).catch((err) => console.error(err));
