import { AfterViewChecked, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AsyncPipe, LowerCasePipe, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { firstValueFrom, Observable, of, Subject, Subscription, switchMap } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { ButtonModule } from 'primeng/button';
import { SkeletonModule } from 'primeng/skeleton';
import { InputNumberModule } from 'primeng/inputnumber';
import { SliderModule } from 'primeng/slider';

import { FinancingCalculatorComponent } from '../financing-calculator/financing-calculator.component';
import { HelperService } from '../../../framework/services/helper.service';
import { BrandService } from '../../services/brand.service';
import { AvailableModel } from '../../../../../common/models/available-model';
import { CampaignCodeButtonComponent } from '../campaign-code-button/campaign-code-button.component';
import { CopService } from '../../services/cop.service';
import { FinancingDetails } from '../../../../../common/models/financing-details';
import { Campaign } from 'src/common/models/campaign';
import { AccordionModule } from 'primeng/accordion';
import { PanelModule } from 'primeng/panel';
import { DateTimeService } from '../../services/datetime.service';
import { CopBrandingResponse } from 'src/common/models/umbraco-responses/cop-branding-response';

@Component({
    selector: 'app-vehicle-model',
    standalone: true,
    imports: [
        NgStyle,
        NgFor,
        NgIf,
        NgClass,
        AsyncPipe,
        FinancingCalculatorComponent,
        AccordionModule,
        PanelModule,
        ButtonModule,
        TranslateModule,
        ButtonModule,
        RouterLink,
        CampaignCodeButtonComponent,
        SkeletonModule,
        InputNumberModule,
        SliderModule,
        LowerCasePipe,
    ],
    templateUrl: './vehicle-model.component.html',
    styleUrl: './vehicle-model.component.scss',
})
export class VehicleModelComponent implements OnInit, AfterViewChecked, OnDestroy {
    @Input() vehicleOid!: string;
    @ViewChild('campaignAccordionTab', { static: false }) campaignAccordionTab!: ElementRef;

    brandNamePreposition = {
        brandName: '',
    };
    financingDetails: FinancingDetails | undefined;
    vehicle: AvailableModel | undefined;
    campaigns$: Observable<Campaign[]> = of([]);
    campaigns: Campaign[] = [];
    copBrand!: CopBrandingResponse;
    campaignColor: string | undefined;
    campaignTextColor: string | undefined;
    stylesApplied = false;
    currentTextColor: string | undefined;
    modelSerieLinkUrl: string | undefined;
    private langChangeSubscription!: Subscription;
    private onDestroy = new Subject<void>();

    constructor(
        private helperService: HelperService,
        private brandService: BrandService,
        private translateService: TranslateService,
        private copService: CopService,
        private router: Router,
        private route: ActivatedRoute,
        private dateTimeService: DateTimeService,
        private renderer: Renderer2,
    ) {
        this.setBrandNamePreposition();
    }

    ngOnInit() {
        this.route.paramMap
            .pipe(
                take(1),
                switchMap((params) => {
                    const vehicleOid = parseInt(params.get('vehicleOid') || '0', 10);
                    return this.copService.getVehicle(vehicleOid);
                }),
            )
            .subscribe({
                next: (vehicle) => {
                    vehicle.ColorName = this.helperService.extractTranslation(
                        vehicle.ColorName,
                        this.brandService.languageKey,
                    );
                    vehicle.DecorName = this.helperService.extractTranslation(
                        vehicle.DecorName,
                        this.brandService.languageKey,
                    );
                    vehicle.ModelDescriptionFin = this.helperService.extractTranslation(
                        vehicle.ModelDescriptionFin,
                        this.brandService.languageKey,
                    );

                    this.vehicle = vehicle;
                    this.fetchAndUpdateCampaigns();
                    this.updateModelSerieLink();
                },
                error: (error) => {
                    console.error('Error fetching vehicle:', error);
                },
            });

            this.brandService.copBrand$
            .pipe(filter(copBrand => Object.keys(copBrand).length > 0), take(1))
            .subscribe({
                next: (copBrand: CopBrandingResponse) => {
                  this.copBrand = copBrand;
                  this.campaignColor = copBrand.properties.campaignColor;
                  this.campaignTextColor = copBrand.properties.campaignTextColor;
                  this.updateModelSerieLink();
            
                  if (this.vehicle) {
                    this.vehicle.EstDeliveryTime += copBrand.properties.estimatedDeliveryDaysAdjustment;
                  }
                }
              });

        this.langChangeSubscription = this.translateService.onLangChange.subscribe(() => {
            this.fetchAndUpdateCampaigns();
        });
    }

    ngAfterViewChecked() {
        this.applyHeaderStyles();
    }

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();
        if (this.langChangeSubscription) {
            this.langChangeSubscription.unsubscribe();
        }
    }

    fetchAndUpdateCampaigns(): void {
        if (this.vehicle && this.vehicle.CampaignCode) {
            const campaignCodes = this.vehicle.CampaignCode.split(',').map((code) => code.trim());
            this.campaigns$ = this.copService.getCampaignsByCodes(campaignCodes);
            this.copService
                .getCampaignsByCodes(campaignCodes)
                .pipe(takeUntil(this.onDestroy))
                .subscribe({
                    next: (campaigns) => {
                        this.campaigns = campaigns;
                        if (campaigns.length === 1) {
                            this.currentTextColor = campaigns[0].campaignTextColor || this.campaignTextColor;
                        } else {
                            this.currentTextColor = this.campaignTextColor;
                        }

                        this.stylesApplied = false;
                        this.applyHeaderStyles();
                    },
                    error: (err) => {
                        console.error('Error fetching campaigns by codes:', err);
                        this.campaigns = [];
                    },
                });
        }
    }

    applyHeaderStyles() {
        if (!this.stylesApplied && this.campaignAccordionTab) {
            const headerElement = this.campaignAccordionTab.nativeElement.querySelector(
                '#campaignAccordionTab_header_action',
            );

            if (headerElement) {
                this.renderer.setStyle(headerElement, 'background-color', this.campaignColor);
                this.renderer.setStyle(headerElement, 'color', this.currentTextColor);
                this.stylesApplied = true;
            }
        }
    }

    formatCurrencyValue(value: string | null, maximumFractionDigits = 2) {
        return this.helperService.formatCurrency(parseFloat(value || '0'), maximumFractionDigits);
    }

    setBrandNamePreposition() {
        this.brandNamePreposition = {
            brandName: this.translateService.instant('label.brandNamePreposition' + this.brandService.brandNameString),
        };
    }

    async goToContactForm(): Promise<void> {
        if (!this.vehicle) {
            return;
        }

        const campaigns = await firstValueFrom(this.campaigns$);

        this.router.navigate(['contact'], {
            relativeTo: this.route,
            state: {
                vehicle: this.vehicle,
                financingDetails: this.financingDetails,
                campaignsDetails: campaigns,
                vehicleUrl: window.location.href,
            },
        });
    }

    setFinancingDetails(financingDetails: FinancingDetails) {
        this.financingDetails = financingDetails;
    }

    getFormattedEndDate(endDate: Date | null): string {
        if (this.translateService.currentLang === 'fi-FI') {
            return this.helperService.formatToFinnishDate(endDate);
        }
        return this.dateTimeService.formatDate(endDate);
    }

    parseInteger(amount: string) {
        return parseInt(amount);
    }

    get brandNameString(): string {
        return this.brandService.brandNameString;
    }

    //It is something to change during we have proper colours/decors per country
    getContentForLanguage(inputString: string) {
        // Use regex to extract content within curly brackets
        const regex = /\{([^}]+)\}/g;

        // Find all matches
        const matches = [...inputString.matchAll(regex)];

        if (this.translateService.currentLang === 'fi-FI') {
            // Return content from the first set of brackets
            return matches[0] ? matches[0][1] : inputString;
        } else if (this.translateService.currentLang === 'en-US') {
            // Return content from the second set of brackets
            return matches[1] ? matches[1][1] : inputString;
        } else {
            return inputString;
        }
    }

    private updateModelSerieLink(): void {
        if (!this.vehicle || !this.copBrand?.properties?.brandModelsBaseUrl) {
            return;
        }
        const baseUrl = this.copBrand.properties.brandModelsBaseUrl;
        const sanitizedModel = this.sanitizeModelSerieName(this.vehicle.ModelSerie);
        this.modelSerieLinkUrl = `${baseUrl}${sanitizedModel}`;
    }

    private sanitizeModelSerieName(modelSerie: string): string {
        if (!modelSerie) {
            return '';
        }

        return modelSerie.replace(/\s+/g, '-');
    }
}
